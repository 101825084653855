* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #eaeaea;
}

.swal-text {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 1px;
  margin: 0;
}

.general-header {
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 17px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  width: 100%;
  z-index: 100;
  top: 0;
}
.general-header .Buttons {
  width: auto;
}
.general-header .Buttons button {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.general-header .Buttons button:first-child {
  margin-right: 10px;
}
.general-header .vendor-info {
  /* display: flex;
  align-items: center; */
  width: 30%;
}
.general-header a {
  width: 50%;
}
.general-header .vendor-info p {
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
}

.general-header h2 {
  color: var(--secondary-color);
  margin: 0;
  letter-spacing: 2px;
  font-weight: bold;
}

.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.m-20 {
  margin: 20px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 550px) {
  .general-header {
    flex-direction: column;
  }
  .general-header h2 {
    font-size: 20px;
    margin: 20px 0;
  }
  .general-header {
    align-items: flex-start;
  }
  .general-header .Buttons {
    align-items: flex-end;
    margin-left: auto;
  }
  .general-header .vendor-info p {
    font-size: 13px;
  }
}
/* Whatsapp chat  */
.FloatingButton__FloatingButtonContainer-sc-q5md4t-0{
  right: 80px !important;
};
