.testimonial-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
    min-height: 320px;
    border-radius: 10px;
    background: #FFF;
    border: 1px solid #F1F1F1;;
    box-shadow: 0 10px 12px 0 rgba(0,80.00000000000017,255,.15);
}

.testimonial-card:hover{
    /* background: #fff; */

}
.testimonial-card img{
    width: 80px;
    border-radius: 50%;
}

.testimonial-card h3{
    color: #000;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: center;
    margin: 10px 0;
}

.testimonial-card .stars-image img{
    width: 100px;
    border-radius: 0;
    margin-bottom: 10px;

}
.testimonial-card p{
    text-align: center;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3em;
}