.choose-us {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.choose-us .bg-left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/Path-2.png);
    background-repeat: no-repeat;
    background-position: right;
}

.choose-us .bg-left h2 {
    color: #000;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}

.choose-us .bg-left p {
    color: #333;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 50px;
}

.choose-us .why-us {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choose-us .why-us .border-right-bottom {}

.choose-us .why-us .border-right-bottom h3 {}

.choose-us .why-us .border-right-bottom p {}

.choose-us .why-us .border-left-bottom {}

.choose-us .why-us .border-left-bottom h3 {}

.choose-us .why-us .border-left-bottom p {}