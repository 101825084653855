.timeline-right{
    margin-left: 20px;
}

.timeline-right h1{
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}

.timeline-right h4{
    color: #333;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.4em;
    text-align: left;

}

.timeline-left{
    margin-right: 20px;
}

.timeline-left h1{
    font-size: 22px;
    font-weight: 600;
    text-align: right;

}

.timeline-left h4{
    color: #333;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.4em;
    text-align: right;
}

.timeline-circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary);
}