.custom404_container {
  /* height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; */
  /* height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif; */
}
.custom404_container h1,
h2 {
  font-family: "Muli";
}
.custom404_container p {
  font-family: "Muli";
}
.bg_image {
  /* The image used */
  background-image: url("../../assets/development.jpg");
  background-color: rgba(221, 215, 215, 0.46);
  opacity: 0.96;
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.custom404_container h1 {
  font-size: 160px;
  font-weight: bolder;
  /* position: relative; */
  color: white;
}
.custom404_container h2 {
  font-size: 40px;
  font-weight: bolder;
  color: white;
}
.custom404_container p {
  font-size: 10px;
  text-align: center;
}
.custom404_container .icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .bg_text {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .custom404_container h1 {
    font-size: 160px;
    font-weight: unset;
  }
  .custom404_container h2 {
    font-size: 40px;
    font-weight: unset;
  }
}
@media screen and (max-width: 425px) {
  .custom404_container h1 {
    font-size: 50px;
    font-weight: unset;
  }
  .custom404_container h2 {
    font-size: 20px;
    font-weight: unset;
  }
}
