.TeamCard_container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* margin-bottom: 50px; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  min-height: 250px;
  min-width: 100%;
  /* margin: 20px; */
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 5px;
  transition: padding-bottom 1s;
  transition: padding-top 1s;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.TeamCard_container:hover {
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  /* transform: translate(10px, 10px); */
  /* padding: 22px; */
  /* animation: postion-change normal 1s linear; */
  padding-top: 20px;
  padding-bottom: 15px;
}
.TeamCard_container img {
  border-radius: 50%;
}
.TeamCard_container h1 {
  font-family: "Muli";
}
.TeamCard_container p {
  font-family: "Open sans" sans-serif;
  color: rgb(131, 131, 131);
}
.team_container {
  margin-bottom: 10px;
}
.heading {
  font-size: 32px;
  margin: 50px 0;
}
@media screen and (max-width: 425px) {
  .team_container {
    margin-bottom: 80px;
  }
}
