.block-chain .talk-expert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/Data-Analytics-1.jpg);
    padding: 70px 0;
}

.block-chain .talk-expert h2{
    color: #FFF;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.block-chain .talk-expert p{
    text-align: center;
    color: #F4F4F4;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

.block-chain .talk-expert button{
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .3px;
    color: #FFF;
    background: #15A70000;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #DBDBDB;
    border-radius: 7px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}