.parent_container .about_container {
  width: 90%;
}
.parent_container .about_container h1 {
  font-family: "Muli";
  font-size: 48px;
  font-weight: bolder;
  letter-spacing: -1px;
  color: var(--text);
}
.parent_container .about_container p {
  font-family: "Open sans" sans-serif;
  font-size: 18px;
  color: var(--text);
}
.parent_container .AboutAnalysis {
  width: 90%;
  margin: 100px 0;
}
.parent_container .AboutAnalysis .AboutTextCard {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.parent_container .AboutAnalysis .AboutTextCard .icon_div,
.about_icon_div {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.parent_container .AboutAnalysis .AboutTextCard .icon_div a,
.about_icon_div a {
  font-family: "Muli";
  font-size: 12px;
}

.parent_container .AboutAnalysis .AboutTextCard a {
  font-weight: bold;
  color: var(--text);
}
.parent_container .AboutAnalysis .AboutTextCard .icon,
.about_icon_div .about_icon {
  color: blue;
  font-size: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.parent_container .AboutAnalysis .AboutTextCard .icon:hover {
  padding-left: 5px;
  font-size: 25px;
  /* position: relative;
  left: 5px; */
}
.parent_container .AboutAnalysis .AboutTextCard h1 {
  font-family: "Muli";
  font-size: 28px;
  font-weight: bolder;
  text-align: left;
  line-height: 1.5;
  letter-spacing: -1px;
  color: var(--text);
}
.parent_container .AboutAnalysis .AboutTextCard p {
  font-family: "Open sans" sans-serif;
  font-size: 16px;
  color: var(--text);
}
.parent_container .RoundProgress {
  width: 90%;
  margin: 100px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.RoundProgress .ant-progress-text {
  color: var(--text) !important;
}
.parent_container .RoundProgress h1 {
  font-family: "Muli";
  font-size: 20px;
  letter-spacing: -1px;
  color: var(--text);
}
.parent_container .RoundProgress p {
  font-family: "Open sans" sans-serif;
  font-size: 16px;
  color: var(--text);
}
.StartYourWebsite_container {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(254, 210, 123, 0.37);
}
.StartYourWebsite_container .StartYourWebsite {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.RoundProgress .ant-progress-circle .ant-progress-text {
  font-weight: 700;
  font-size: 64px !important;
}
.StartYourWebsite_container .StartYourWebsite h1 {
  font-family: "Muli";
  font-size: 32px;
  font-weight: bold;
  color: var(--text);
}
.StartYourWebsite_container .StartYourWebsite button {
  font-family: "Roboto" sans-serif;
  font-size: 12px;
  background-color: var(--primary);
  color: white;
}
.parent_container .about_container .mobileApp {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .parent_container .about_container .mobileApp {
    text-align: center;
    /* margin: 100px 0; */
  }
}
@media screen and (max-width: 425px) {
  .StartYourWebsite_container .StartYourWebsite {
    text-align: center;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    gap: 30px;
  }
  .parent_container .RoundProgress {
    margin: 50px 0;
  }
  .statistic_container {
    height: 300px !important;
  }
  .parent_container .about_container h1 {
    text-align: left;
    font-size: 28px;
  }
  .parent_container .about_container p {
    font-size: 14px;
    text-align: left;
  }
  .parent_container .AboutAnalysis {
    margin: unset;
  }
  .parent_container .AboutAnalysis .AboutTextCard h1 {
    font-size: 24px;
  }
  .parent_container .AboutAnalysis .AboutTextCard p {
    font-size: 14px;
  }
  .AboutAnalysis {
    margin-bottom: unset !important;
  }
}
