.service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
}

.service-container .count {
    width: 90%;
    padding: 48px 0 64px;

}


.service-container .count .count-content h3 {
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 72px; */
    letter-spacing: -4px;
    text-align: center;
    color: var(--primary);
}

.service-container .count .count-content .days {
    font-size: 36px;
    letter-spacing: -1px;
    font-weight: 700;
    color: var(--primary);

}

.service-container .count .count-content p {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -.6px;
    text-align: center;
    color: #000;

}

.service-container .main-carousel {
    width: 80%;
    height: 460px;
    padding: 60px 0;
}

.service-container .solutions {
    width: 70%;
    padding: 75px 0;
}

.service-container .solutions .solutions-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-container .solutions .solutions-content h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    color: #000;
    margin-bottom: 24px;
    text-align: left;
}

.service-container .solutions .solutions-content h5 {
    color: #2e384d;
    opacity: .5;
    font-weight: 400;
    letter-spacing: -1.2px;
    font-size: 22px;
    line-height: 32px;
    width: 65%;
    margin-bottom: 3rem;
    text-align: center;
}

.service-container .solutions .solutions-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -.4px;
    text-align: left;
    color: #000;
}

.service-container .solutions .solutions-content .technologies {
    width: 90%;
    margin: 75px auto auto auto;
}

.service-container .experts {
    background-color: var(--primary);
    width: 100%;
    padding: 92px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .experts .experts-content {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .experts .experts-content h3 {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1px;
    text-align: center;
    color: #fff;
    margin-bottom: 24px;
}

.service-container .experts .experts-content p {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    color: #fff;
    margin-bottom: 48px;
}

.service-container .experts .experts-content button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .experts .experts-content button:hover {
    color: #fff;
    background: #000;
}

.service-container .dedicated-team {
    padding: 90px 0;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .dedicated-team .dedicated-team-content h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -2px;
    color: #000;
    margin-bottom: 24px;
}

.service-container .dedicated-team .dedicated-team-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -.4px;
    text-align: left;
    color: #000;
    margin-bottom: 40px;
}

.service-container .dedicated-team .context {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.service-container .dedicated-team .context .augmentation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .dedicated-team .context .augmentation img {
    width: 120px;
    height: 120px;
}

.service-container .dedicated-team .context .augmentation h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -.6;
    text-align: center;
}

.service-container .dedicated-team .context .leads {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .dedicated-team .context .leads img {
    width: 100%;
    height: auto;
}

.service-container .offer {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 96px 0;
}

.service-container .offer .offer-image {
    margin-bottom: 24px;
}

.service-container .offer .offer-image h2 {
    text-shadow: 2px 2px 0 #ffff, 2px -2px 0 #ffff, -2px 2px 0 #ffff, -2px -2px 0 #ffff, 2px 0px 0 #ffff, 0px 2px 0 #ffff, -2px 0px 0 #ffff, 0px -2px 0 #ffff;
    color: var(--primary);
    letter-spacing: 2.3px;
    font-size: 65px;
    line-height: 1.2;
    margin-bottom: 24px;
}

.service-container .offer .offer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.service-container .offer .offer-content p {
    max-width: 551px;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    color: #fff;
    text-align: left;
}

.service-container .offer .offer-content button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .offer .offer-content button:hover {
    color: #fff;
    background: #000;
}

.service-container .best-fit {
    padding: 90px 0;
    width: 75%;

}

.service-container .best-fit .best-fit-content h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    text-align: left;
    color: #000;
    margin-bottom: 24px;
}

.service-container .best-fit .best-fit-content p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #2e384d;
    opacity: .6;
    margin-bottom: 40px;
}

.service-container .best-fit .situation {
    padding: 24px 32px;
    cursor: pointer;
}

.service-container .best-fit .situation h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -.6px;
    text-align: left;
    color: var(--primary);
    text-transform: uppercase;
    margin-bottom: 16px;
}

.service-container .best-fit .situation .experties {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-container .best-fit .situation .experties p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
}

.service-container .best-fit .situation .experties svg {
    font-size: 20px;
    color: var(--primary);
}

.service-container .best-fit .solution {
    padding: 24px 32px 0 32px;

}

.service-container .best-fit .solution p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -.4px;
    text-align: left;
    color: #000;
}

.service-container .best-fit .solution h6 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.6px;
    text-align: left;
    color: #000;
    margin-top: 48px;
    margin-bottom: 14px;
}

.white-box {
    background-color: #fff;
    box-shadow: 0 20px 32px -4px rgb(16 24 40 / 6%), 0 8px 16px -4px rgb(16 24 40 / 2%);
    border-radius: 12px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .challenge {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 96px 0;
}

.service-container .challenge .sound-challenge {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .challenge .sound-challenge img {
    width: 100%;
}


.service-container .challenge .arrow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 40px;
}

.service-container .challenge .talk-btn {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    height: 170px;
    margin-left: 40px;
}

.service-container .challenge .talk-btn button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .challenge .talk-btn button:hover {
    color: #fff;
    background: #000;
}

.service-container .type-of-work {
    padding: 96px 0;
    width: 85%;
}

.service-container .type-of-work h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    text-align: left;
    color: #000;
    margin-bottom: 48px;
}

.service-container .type-of-work .type-of-work-content .flex {
    display: flex;
    justify-content: space-between;
}

.service-container .type-of-work .type-of-work-content .image-container {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #656c7b;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-container .type-of-work .type-of-work-content .items-container {
    width: 20%;
}

.service-container .type-of-work .type-of-work-content .items-container .items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 32px;
}

.service-container .type-of-work .type-of-work-content .items-container .items p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
}

.service-container .type-of-work .cooperation {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.service-container .type-of-work .cooperation img {
    width: 350px;
}

.service-container .tech-audit {
    background: url('../../assets/working-on-macbook.webp');
    background-repeat: no-repeat;
    background-position: right;
    height: 700px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
}

.service-container .tech-audit .technical-audit {
    background-color: #532456;
    min-height: 500px;
    padding: 0 32px;
    width: 635px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -400px;

}

.service-container .tech-audit .technical-audit h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    text-align: left;
    color: #fff;
    margin-bottom: 24px;
}

.service-container .tech-audit .technical-audit p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #fff;
    opacity: .7;
    margin-bottom: 26px;
}

.service-container .tech-audit .technical-audit h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    color: #d91965;
    display: block;
    margin-bottom: 50px;
}

.service-container .tech-audit .technical-audit img {
    margin-left: 15px;
}

.service-container .tech-audit .technical-audit button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .tech-audit .technical-audit button:hover {
    color: #fff;
    background: #000;
}

.service-container .extend-work {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .extend-work .extend-work-content {
    width: 85%;
}

.service-container .extend-work .extend-work-content h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -2px;
    color: #000;
    margin-bottom: 24px;
}

.service-container .extend-work .extend-work-content p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #656c7b;
}

.service-container .extend-work .extend-work-content .qualities {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #d4d5d7;
    padding: 32px 0;
}

.service-container .extend-work .extend-work-content .qualities h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.6px;
    text-align: left;
    /* color: #d91965; */
    color: var(--primary);
    width: 60%;
}

.service-container .extend-work .extend-work-content .qualities p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -.3px;
    text-align: left;
    color: #000;
    width: 40%;
}

.service-container .awards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .awards .awards-content {
    width: 80%;
}

.service-container .awards .awards-content h2 {
    font-size: 64px;
    line-height: 72px;
    margin-top: 96px;
    font-weight: 700;
    letter-spacing: -3px;
    text-align: left;
    color: #000;
}

.service-container .awards .awards-content .awards-img {
    padding: 81px 0 137px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.service-container .awards .awards-content .awards-img img {
    width: 150px;
}

.service-container .with-decotechs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary);
    padding: 96px 0;
}

.service-container .with-decotechs .flex {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-container .with-decotechs .flex .team {
    width: 70%;
}

.service-container .with-decotechs .flex .team h2 {
    text-shadow: 2px 2px 0 #ffff, 2px -2px 0 #ffff, -2px 2px 0 #ffff, -2px -2px 0 #ffff, 2px 0px 0 #ffff, 0px 2px 0 #ffff, -2px 0px 0 #ffff, 0px -2px 0 #ffff;
    color: var(--primary);
    letter-spacing: 2.3px;
    font-size: 65px;
    line-height: 1.2;
    margin-bottom: 24px;
}

.service-container .with-decotechs .flex .team p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #fff;
    max-width: 551px;
}

.service-container .with-decotechs .flex .btn-container {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .with-decotechs .flex .btn-container p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #fff;
    padding: 10px 0;
}

.service-container .with-decotechs .flex .btn-container .hire {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    background: #000;
}

.service-container .with-decotechs .flex .btn-container .hire:hover {
    color: #000;
    background: #fff;
}

.service-container .with-decotechs .flex .btn-container .tech {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .with-decotechs .flex .btn-container .tech:hover {
    color: #fff;
    background: #000;
}

.service-container .schedule {
    width: 80%;
    padding: 96px 0;
}

.service-container .schedule .schedule-content h2 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -3px;
    color: #000;
    margin-bottom: 1.7rem;
    max-width: 550px;
}


.service-container .schedule .schedule-content p {
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: -.4px;
    margin-bottom: 3.61rem;
    color: #656c7b;
}

.service-container .schedule .expert .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-container .schedule .expert .flex .person {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 50%;

}

.service-container .schedule .expert .flex .person img {
    width: 80px;
    border-radius: 100%;
}

.service-container .schedule .expert .flex .person .person-details h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #000;
}

.service-container .schedule .expert .flex .person .person-details p {
    font-size: 14px;
    line-height: 26px;
    color: #000;
}

.service-container .schedule .expert .flex .content {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.service-container .schedule .expert .flex .content p {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -.4px;
}

.service-container .schedule .booking {
    margin-top: 3.61rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .schedule .booking .flex {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.service-container .schedule .booking .flex h6 {
    font-size: 2rem;
    line-height: 1.55556;
    font-weight: 700;
    color: #000;
}

.service-container .schedule .booking .flex p {
    font-size: 22px;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .schedule .booking .flex svg {
    margin-left: 10px;
    font-size: 22px;

}

.service-container .schedule .booking .calendar {
    width: 70%;
    box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
    background: #fff;
    padding: 60px 120px;
}

.service-container .schedule .booking .calendar .calendar-box {
    box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
    border-radius: 8px;
    padding: 20px;
}

.service-container .schedule .booking .calendar .calendar-box .ant-picker-calendar-date-today {
    background: var(--primary) !important;
    border: 1px solid var(--primary);
}

.service-container .schedule .booking .calendar .calendar-box .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--primary) !important;
    border: 1px solid var(--primary);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
}

.service-container .schedule .booking .calendar .calendar-box .ant-radio-button-wrapper-checked {
    color: var(--primary);
    border-color: var(--primary);
    box-shadow: none;
}

.service-container .schedule .booking .calendar .calendar-box .ant-radio-button-wrapper:hover {
    color: var(--primary);
    border-color: var(--primary);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--primary);
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--primary);
}

.react-multi-carousel-dot-list {
    justify-content: right !important;
}

.situationresponsive {
    display: none;
}

@media (max-width:1440px) {
    .service-container .main-carousel {
        height: 560px;
    }
}

@media (max-width: 1250px) {
    .service-container .count .count-content h3 {
        font-size: 45px;
    }

    .service-container .count {
        width: 90%;
        padding: 40px 0 30px;
    }
}

@media (max-width: 1050px) {
    .service-container .solutions {
        width: 90%;
    }

    .service-container .solutions .solutions-content h5 {
        width: 95%;
    }

    .service-container .experts .experts-content {
        width: 90%;
    }

    .service-container .dedicated-team {
        width: 90%;
        padding-top: 60px;
        padding-bottom: 0;
    }

    .width65 {
        width: 90%;
    }

    .service-container .best-fit {
        width: 90%;
    }

    .service-container .type-of-work {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-container .type-of-work .type-of-work-content .flex {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-container .type-of-work .type-of-work-content .image-container {
        width: 90%;
    }

    .service-container .type-of-work .cooperation img {
        width: 250px;
    }

    .service-container .type-of-work .type-of-work-content .items-container {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .service-container .extend-work .extend-work-content {
        width: 90%;
    }

    .service-container .awards .awards-content {
        width: 90%;
    }

    .service-container .schedule .schedule-content h2 {
        max-width: 100%;
    }

    .service-container .challenge .talk-btn {
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .service-container .challenge .arrow {
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .service-container .challenge .sound-challenge img {
        width: 80%;
    }
}

@media (max-width:770px) {
    .service-container .main-carousel {
        height: 670px;
    }

    .service-container .with-decotechs .flex {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .service-container .with-decotechs .flex .team {
        width: 90%;

    }

    .service-container .with-decotechs .flex .btn-container {
        width: 90%;
        margin-top: 25px;
    }

    .service-container .schedule .expert .flex .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        gap: 5px;
    }

    .service-container .schedule .expert .flex .person .person-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-container .schedule .expert .flex {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .service-container .schedule .expert .flex .content {
        width: 90%;
    }

    .service-container .schedule .booking .flex {
        width: 100%;
    }

    .service-container .schedule .booking .calendar {
        width: 100%;
    }

    .service-container .schedule {
        width: 90%;
        padding: 60px 0;
    }

    .service-container .tech-audit .technical-audit {
        margin-left: 0;
    }

    .service-container .type-of-work {
        padding: 50px;
    }

    .service-container .extend-work .extend-work-content .qualities {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-container .extend-work .extend-work-content .qualities h3 {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .service-container .extend-work .extend-work-content .qualities p {
        text-align: center;
        width: 100%;
    }

    .service-container .with-decotechs .flex .team h2 {
        font-size: 38px;
    }

    .service-container .offer .offer-image h2 {
        font-size: 38px;
        text-align: center;
    }
    .service-container .offer .offer-content button{
        margin-top: 20px;
    }
    .service-container .offer .offer-content{
        justify-content: center;
    }
    .service-container .with-decotechs {
        padding: 60px 0;
    }

    .service-container .solutions .solutions-content .technologies {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin: 75px auto auto auto;
    }

    .situationdesktop {
        display: none;
    }

    .situationresponsive {
        display: block;

    }
}

@media (max-width:550px) {
    .service-container .main-carousel {
        height: 895px;
        width: 90%;
    }

    .service-container .schedule .booking .calendar {
        padding: 0;
    }

    .service-container .schedule {
        padding-bottom: 30px;
    }

    .service-container .challenge .sound-challenge img {
        width: 90%;
    }

}