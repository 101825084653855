.footer_container {
  /* background-color: var(--footerBright);
   */
  /* background: rgb(43, 126, 194);
  background: linear-gradient(
    90deg,
    rgba(43, 126, 194, 1) 0%,
    rgba(81, 190, 237, 1) 100%
  ); */
  background: rgb(3, 62, 111);
  background: linear-gradient(
    0deg,
    rgba(3, 62, 111, 1) 0%,
    rgba(81, 190, 237, 1) 100%
  );
  /* padding: 50px; */
  width: 90%;
}
.footer_container h1 {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: white;
}
.footer_container p {
  font-size: 16px;
  font-family: "Open sans" sans-serif;
  color: white;
}
.footer_container h3 {
  font-size: 16px;
  font-family: "Open sans" sans-serif;
  color: white;
}
.footer_container .footer_logo,
.quickLinks,
.tweet,
.appStore {
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
  row-gap: 1rem;
  color: white;
  padding-top: 2rem;
}
.footer_container .quickLinks .icon_div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.footer_container .quickLinks .icon_div svg {
  display: none;
  color: white;
  font-size: 20px;
}
.footer_container .quickLinks .icon_div:hover svg {
  display: block;
}
.footer_container .quickLinks p {
  cursor: pointer;
  color: white;
}
.footer_container h1,
h3 {
  color: white;
}
.footer_container .copyright {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.footer_container .copyright .icon {
  color: black;
  background-color: white;
  border-radius: 50%;
  margin: 10px;
  padding: 8px;
}
.footer_container .copyright p {
  font-size: 14px;
  font-family: "Open sans" sans-serif;
  color: white;
}
.footer_container .copyright .icon:hover {
  /* background-color: white;
   */
  cursor: pointer;
}
/* .waveImage {
  margin-bottom: -1px;
} */
.quickLinks .split_div {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  /* .waveImage {
    bottom: 875px;
  } */
  .waveImage {
    display: none;
  }
  .footer_container {
    margin: 15px 0;
  }
}
@media screen and (max-width: 768px) {
  /* .waveImage {
    bottom: 994px;
  } */
  .footer_container .copyright {
    flex-direction: column;
    gap: 20px;
    padding: unset;
  }
  .quickLinks {
    flex-direction: column;
    /* gap: 50px; */
  }
  .quickLinks .split_div {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
  }
  .appStore div {
    flex-direction: row;
    margin-top: 10px;
  }
  .footer_container .tweet,
  .footer_container .appStore,
  .footer_container .footer_logo {
    gap: unset;
  }
  .footer_container .footer_row {
    display: flex;
    gap: 30px;
  }
}
/* @media screen and (max-width: 375px) {
  .waveImage {
    bottom: 1015px;
  }
}
@media screen and (max-width: 320px) {
  .waveImage {
    bottom: 1065px;
  }
} */
