.headerDrawer_container {
  /* padding: 10px; */
  /* background-color: var(--background); */

}
.HeaderDrawer .ant-drawer-body {
  background-color: var(--background) !important;
}
.headerDrawer_container .headerDrawer_links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headerDrawer_logo div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerDrawer_logo div svg {
  display: none;
}
.headerDrawer_container .headerDrawer_links p {
  cursor: pointer;
  /* font-weight: bold; */
  font-size: 20px;
  font-family: "Open sans" sans-serif;
  letter-spacing: -1px;
  color: var(--text);
}
/* .headerDrawer_container .headerDrawer_logo h1 {
  font-family: "Muli";
  font-size: 48px;
} */
.headerDrawer_container .headerDrawer_logo p {
  font-family: "Open sans";
  font-size: 14px;
  margin-top: 20px;
  color: var(--text);

}
.headerDrawer_container .line {
  background-color: #cecece;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}
.headerDrawer_container .contacts p {
  font-size: 14px;
  color: var(--text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.headerDrawer_container .contacts svg {
  margin-right: 10px;
}

.ant-drawer-header {
  border-bottom: none !important;
  width: 30%;
  display: none !important;
  top: 13px !important;
  position: absolute !important;
  left: 300px !important;
}
.ant-drawer-header-title {
  justify-content: flex-end !important;
}
@media screen and (max-width: 425px) {
  /* .ant-drawer-header {
    display: block !important;
  } */
  .headerDrawer_logo div svg {
    display: block;
  }
}
