.banner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
}

.banner .width75{
    margin-top: 10%;
}

.banner .banner-content h1{
    font-size: 72px;
    line-height: 72px;
    font-weight: 700;
    letter-spacing: -3.6px;
    margin-bottom: 1.22rem;
    color: #fff;
}

.banner .banner-content p{
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: -.4px;
    color: #fff;
}

.banner .bannerImage{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.banner .banner-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner .banner-btn button{
    min-width: 200px;
    height: 60px;
    color: #000;
    text-align: center;
    margin-bottom: 5.36rem;
    margin-top: 2.66rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    background: #fff;

}

.banner .banner-btn button:hover{
    color: #fff;
    background: #000;
}

.banner .features{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #2e384d;

}

.banner .features .features-cards{
    width: 11%;
    text-align: center;
    min-height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.banner .features .features-cards p{
    font-size: 17px;
    line-height: 21px;
    color: #fff;
}

.black-color{
    background-color: #000;
}

.width75{
    width: 75%;
  }
  
  .width85{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
  }

  .width65{
    width: 65%;
  }

  @media (max-width: 1320px){
    .banner .features .features-cards p{
        font-size: 12px;
    }
}

  @media (max-width: 1250px){
    .width75{
        width: 90%;
    }
    .banner .banner-content h1{
        font-size: 52px;
    }
  }


  @media (max-width: 980px){
.banner .features{
    display: none;
}
  }
  @media (max-width: 550px){
    .width75{
        width: 95%;
    }
    .banner .banner-content h1{
        font-size: 32px;
    }
  }