.why-should{
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;
    padding: 10px 25px;
    min-height: 510px;
    background: #fff;
    border-radius: 10px;
}

.why-should h1{
    font-size: 45px;
    font-weight: 600;
    text-align: left;
    color: var(--primary);
}

.why-should h3{
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: left;
    margin: 20px 0;
}

.why-should p{
    text-align: left !important;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3em;
    margin: 0 !important;
}