.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar);
}

.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-switch-handle {
  top: 3px !important;
  width: 15px !important;
  height: 15px !important;
  left: 7px;
}
.ant-switch-checked .ant-switch-inner {
  margin-left: 3px !important;
}
.ant-drawer-content-wrapper {
  /* width: 100% !important; */
}
.v-layout .company-logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
}
.v-layout .company-logo img {
  width: 70%;
  height: 100px;
}
.v-layout .company-title {
  font-size: 22px;
  color: var(--text) !important;
  text-transform: uppercase;
}
.header-container {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: var(--background);
  z-index: 10;
}

.header-container .left {
  cursor: pointer;
  padding: 30px;
  width: 80%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 30px;
}
.header-container .left img{
  height: 30px;
  width: 190px;
  margin-right: 60px;
}
.header-container .left h2 {
  font-family: 'Muli';
  font-size: 16px;
  width: max-content;
  color: var(--textColor);
}
.header-container .header_btn {
  width: 20%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding: 20px;
  align-items: center;
}
.header-container h1 {
  font-size: 25px;
  font-weight: bolder;
  color: var(--text);
}
header-container h2 {
  font-size: 12px;
  font-weight: bold;
  color: var(--text);

}
.smallScreen_container {
  display: none;
}
.header_btn .btn {
  cursor: pointer;
  background-color: var(--primary);
  color: white;
  border: none;
  height: 38px;
  width: 120px;
  border-radius: 50px;
  font-family: "Muli";
  font-size: 12px;
  
}
@media(max-width : 1560px){
  .header-container .left h2{
    font-size: 11px
  }
}
@media(max-width : 1320px){
  .header-container .left img{
    margin-right: 0;
  }
}
@media(max-width:1220px){
  .header-container .left img{
    height: 20px;
    width: 100px;
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header-container {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .smallScreen_container {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
  }
}
