.case-study-card{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 60px 40px;
    border-radius: 8px;
    margin: 30px 0;
    background: #fff;
}

.case-study-card h3{
    font-size: 22px;
    color: var(--primary);
    font-weight: 700;
    border-right: 4px solid var(--primary);

}

.case-study-card p{
    font-family: 18px;
    color: #000;
}

@media (max-width: 990px){
.case-study-card h3{
    border-right: none;
}
}

@media (max-width: 550px){
.case-study-card{
    padding: 30px 20px;
}
.case-study-card h3{
    text-align: justify;
}
.case-study-card p{
    text-align: justify;

}

}