.case-study{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.case-study .head{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
    padding: 60px 0;
}

.case-study .head h2{
    font-size: 46px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-decoration: underline;
}

.case-study .head p{
    width: 60%;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-top: 20px;

}

.case-study .card-div{
    width: 90%;
}


@media(max-width: 990px){
.case-study .head p{
    width: 90%;
}
} 

