.client-testimonial{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* background: var(--primary); */
}
.client-testimonial .banner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
    padding: 60px 0;
}

.client-testimonial .banner p{
    width: 70%;
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin-bottom: 10px;
}
.client-testimonial .width90{
    width: 90%;
    padding: 60px 0;
}