.solution-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F5F7FF;
    padding: 10px 25px;
    min-height: 550px;
    background: #F5F7FF;
    border-radius: 10px;
}

.solution-card:hover {
    background: #fff;
    box-shadow: 0 10px 12px 0 rgba(0, 80.00000000000017, 255, .15);
}

.solution-card img {
    width: 90px;
}

.solution-card h3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: center;
    margin: 20px 0;
}

.solution-card p {
    text-align: center;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3em;
}