.main-banner-container {
  background: var(--primary);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* min-height: 80vh; */
  min-height: 38rem;
  position: relative;
}

.main-banner-container .width75 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
}

.main-banner-container .heading {
  width: 49%;
}

.main-banner-container .heading h1 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 18px;
}
.main-banner-container .heading h3 {
  font-size: 37px;
  font-weight: 500;
  line-height: 1.35em;
}

.main-banner-container .heading .strong-underline {
  font-weight: 700;
  text-decoration: underline;
}

.main-banner-container .heading .consultation-call {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  gap: 20px;
  margin-top: 30px;
}

.main-banner-container .heading .consultation-call .icon {
  background: #fff;
  border-radius: 7px;
  /* padding: 10px; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-banner-container .heading .consultation-call .icon svg {
  color: var(--primary);
  font-size: 20px;
}
.main-banner-container .heading .consultation-call h6 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 7px;
}

.main-banner-container .image {
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-banner-container .image img {
  width: 100%;
}
.main-banner-container .reviews-count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: absolute;
  bottom: -90px;
}

.main-banner-container .reviews-count .reviews-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  background: #fff;
  padding: 6px 10px;
  border-radius: 10px;
}

.main-banner-container .reviews-count .reviews-head .gmb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.main-banner-container .reviews-count .reviews-head .gmb img {
  width: 65px;
  margin-left: 20px;
}

.main-banner-container .reviews-count .reviews-head .gmb h3 {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 600;
}

.main-banner-container .reviews-count .reviews-head .gmb h6 {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  background-color: #f3f3f3;
  border-radius: 6px 6px 6px 6px;
}

.main-banner-container .reviews-count .reviews-head .stars {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-banner-container .reviews-count .reviews-head .stars img {
  margin-right: 20px;
  width: 120px;
}

.main-banner-container .reviews-count .ratings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  background: #fff;
  padding: 10px 40px;
}

.main-banner-container .reviews-count .ratings img {
  width: 78px;
}

@media (max-width: 1050px) {
    .main-banner-container{
        min-height: 48rem;
    }
  .main-banner-container .width75 {
    flex-direction: column;
    width: 90%;
  }
  .main-banner-container .heading {
    width: 100%;
  }
  .main-banner-container .image {
    width: 90%;
  }
  .main-banner-container .reviews-count .reviews-head {
    width: 90%;
  }
  .main-banner-container .reviews-count .ratings {
    width: 80%;
  }
}


@media(max-width: 550px) {
  .main-banner-container .reviews-count .ratings img{
    width: 30px;
  }
  .main-banner-container .reviews-count .reviews-head .gmb img{
    width: 40px;
  }
  .main-banner-container .reviews-count .reviews-head .gmb h3{
    font-size: 10px;
  }
  .main-banner-container .reviews-count .reviews-head .gmb h6{
    font-size: 10px;
  }
  .main-banner-container .reviews-count .reviews-head .stars img{
    width: 50px;
  }
}