.PortfolioSummery {
  width: 90%;
  /* display: flex;
  gap: 50px; */
}
.PortfolioSummery .PortfolioText {
  margin-top: 50px;
  /* width: 30vw; */
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;
  /* position: sticky; */
}
/* .PortfolioSummery .PortfolioText h1 {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--text);
} */
.PortfolioSummery .PortfolioText a {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--text);
}
.PortfolioSummery .PortfolioText a:hover{
  cursor: pointer;
  color: var(--primary);
}
.PortfolioSummery .PortfolioText p {
  font-size: 16px;
  font-family: "Open sans";
  color: var(--text);
}
.PortfolioSummery .PortfolioText .category {
  display: flex;
  align-items: center;
  gap: 10px;
}
.PortfolioSummery .PortfolioText .social {
  display: flex;
  align-items: center;
  gap: 10px;
}
.PortfolioSummery .PortfolioText .social div {
  display: flex;
  gap: 10px;
  margin: 50px 0;
}
.PortfolioSummery .PortfolioText .category h2,
.PortfolioSummery .PortfolioText .social h2 {
  font-family: "Muli";
  font-size: 16px;
  color: var(--text);

}

.PortfolioSummery .PortfolioText .category li {
  font-size: 16px;
  font-family: "Open sans";
  color: rgb(124, 124, 124);
  color: var(--text);

}
.PortfolioSummery .PortfolioText .category div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.PortfolioSummery .PortfolioImages {
  /* width: 70vw; */
}
/* .slick-dots {
  background-color: rgb(0, 0, 0) !important;
  height: 10px !important;
  width: 20px !important;
} */

.carousal_Style {
  /* height: 500px; */
  width: 100%;
  color: #fff;
  line-height: 160px;
  text-align: center;
  /* //   background: #364d79; */
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .carousal_Style {
    height: 300px !important;
  }
}
@media screen and (max-width: 425px) {
  .carousal_Style {
    height: 200px !important;
  }
}
