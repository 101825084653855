.workflow_heading {
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: var(--text);

}
.about_container .margin  {
  margin-bottom: 150px;
}
.workflow_heading h1 {
  font-size: 35px;
  font-family: "Muli";
  color: var(--text);

}
.workflow_heading p {
  font-size: 16px;
  font-family: "Open sans";
  width: 55%;
  color: var(--text);

}
.WorkflowTemplate {
  width: 90%;
}
.WorkflowTemplate .image_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.WorkflowTemplate .image_div img {
  width: 80%;
}
.WorkflowTemplate .icon {
  background-color: var(--background);
  box-shadow: var(--boxShadow);
  width: 100px;
  height: 100px;
  padding: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WorkflowTemplate .right img {
  width: 70px;
  /* border-radius: 50%; */
}
.WorkflowTemplate .right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.WorkflowTemplate .right h1,
.WorkflowTemplate .left h1 {
  font-size: 32px;
  font-family: "Muli";
  color: var(--text);

}
.WorkflowTemplate .right p,
.WorkflowTemplate .left p {
  color: rgb(124, 124, 124);
  width: 80%;
  font-size: 16px;
  font-family: "Open sans";
  color: var(--text);

}
.WorkflowTemplate .right {
  padding-right: 30px;
}
.WorkflowTemplate .left {
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  .workflow_heading p {
    width: unset;
  }
  .about_container .margin {
    margin-bottom: unset;
  }
}
@media screen and (max-width: 425px) {
  .WorkflowTemplate .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin: 50px 0;
  }
  .WorkflowTemplate .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .WorkflowTemplate .left {
    text-align: center;
  }
  .WorkflowTemplate .image {
    display: none;
  }
  .workflow_heading h1 {
    font-size: 24px;
  }
  .workflow_heading p {
    font-size: 14px;
  }
  .WorkflowTemplate .right h1,
  .WorkflowTemplate .left h1 {
    font-size: 24px;
  }
  .WorkflowTemplate .right p,
  .WorkflowTemplate .left p {
    font-size: 14px;
  }
  .WorkflowTemplate .left {
    padding: unset;
  }
  .WorkflowTemplate .right {
    padding: unset;
  }
}
