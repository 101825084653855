.clients_container .ourClients {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.clients_container .ourClients h1 {
  font-family: "Muli";
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -1px;
  color: var(--text);
}
.clients_container .ourClients h2 {
  font-family: "Muli";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  color: var(--text);
}
.clients_container .ourClients p {
  font-family: "Open sans";
  width: 50%;
  font-size: 16px;
  color: var(--text);
}
.clients_container .brands_container {
  width: 90%;
  text-align: center;
  /* margin-bottom: 100px; */
}
.brands_container .img {
  height: 70px;
  filter: grayscale(60%);
}
.brands_container .brand {
  height: 60px;
  filter: grayscale(100%);
}
.clients_container .brands_container .image_container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clients_container .brands_container img {
  /* -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; */
}
.clients_container .brands_container img:hover {
  padding: 5px;
  transition: 0.3s ease-in;
  transform: scale(1.3);
}
@media screen and (max-width: 768px) {
  .brands_container .brand {
    height: 30px;
  }
  .clients_container .brands_container .image_container {
    min-height: 110px;
  }
  .clients_container .brands_container {
    margin: 50px 0;
  }
}
@media screen and (max-width: 425px) {
  .clients_container .ourClients {
    padding: 10px;
  }
  .clients_container .ourClients h1 {
    font-size: 30px;
  }
  .clients_container .ourClients h2 {
    font-size: 20px;
  }
  .clients_container .ourClients p {
    width: 100%;
  }
  .brands_container .brand {
    height: 40px;
  }
  .brands_container .img {
    height: 50px;
  }
  .clients_container .brands_container .image_container {
    min-height: 90px;
  }
}
