.blogGrid_container .blogGridText_Container {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bolder;
  font-family: "Muli";
  letter-spacing: -1px;
}
.blogGrid_container .blogGridText_Container h1 {
  color: var(--text);
}
.LoadMore {
  background-color: var(--background);
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  /* margin: 50px 0; */
}
.LoadMore h1 {
  font-family: "Muli";
  font-weight: bold;
  font-size: 12px;
  color: var(--text);
}
.LoadMore .icon {
  font-size: 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  color: var(--text);
}
.LoadMore .icon:hover {
  /* margin-top: 10px; */
  padding-top: 10px;
  font-size: 30px;
  /* position: relative;
  top: 10px; */
}
.blogGrid_container .gridCards_container {
  width: 90%;
  margin-bottom: 100px;
}
.blogGrid_container .gridCards_container .DesignCard a {
  font-size: 12px;
  color: var(--text);
}
.blogGrid_container .gridCards_container .DesignCard h1 {
  font-size: 22px;
  letter-spacing: -1px;
  color: var(--text);
}
.blogGrid_container .gridCards_container .DesignCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blogGrid_container .gridCards_container .DesignCard .icon_div {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.blogGrid_container .gridCards_container .DesignCard .icon {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 20px;
}
.blogGrid_container .gridCards_container .DesignCard .icon:hover {
  padding-left: 5px;
  font-size: 25px;
}
.blogGrid_container .gridCards_container img {
  width: 100%;
}
.blogGrid_container .gridCards_container h1 {
  font-family: "Muli";
  color: var(--text);
}
.blogGrid_container .gridCards_container p {
  font-family: "Open sans";
  color: var(--text);
}
.blogGrid_container .gridCards_container a {
  font-family: "Muli";
  /* color: black; */
  font-size: 10px;
  color: var(--text);
}
@media screen and (max-width: 425px) {
  .blogGrid_container .blogGridText_Container {
    font-size: 28px;
    padding: 50px;
  }
  .blogGrid_container .gridCards_container {
    margin-bottom: unset;
  }
}
