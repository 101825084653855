.parent_container .DataCards {
  width: 90%;
  text-align: center;
  margin: 100px 0;
  background-color: var(--background);
}
.ServicesCard {
  /* padding: 20px; */
  /* margin-bottom: 50px; */
  /* background-color: red; */
  cursor: pointer;
  min-height: 325px;
  min-width: 325px;
  /* margin: 20px; */
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: padding-bottom 1s;
  transition: padding-top 1s;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.ServicesCard:hover {
  box-shadow: var(--boxShadow);
  /* transform: translate(10px, 10px); */
  /* padding: 22px; */
  /* animation: postion-change normal 1s linear; */
  padding-top: 20px;
  padding-bottom: 15px;
  /* margin-bottom:55px; */
  /* background-color : blue */
}
.ServicesCard h1 {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--text);
}
.ServicesCard p {
  font-family: "Open Sans" sans-serif;
  font-size: 14px;
  color: var(--text);
}

/* @keyframes postion-change {
  from {transition: rotate(0deg);}
  to {transition: rotate(1deg);}

} */

.Services_Container .ModernClean {
  width: 90%;
  margin: 100px 0;
}
.Services_Container .ModernClean div {
  min-height: 400px;
}
.Services_Container .ModernCards {
  min-height: 300px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
  padding: 10px;
  /* padding-top: 25px;
  padding-bottom: 5px;
  transition: padding-bottom 1s;
  transition: padding-top 1s; */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* padding: 50px 20px; */
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
/* .Services_Container .ModernCards h1,
svg {
  margin-bottom: 25px;
}
.Services_Container .ModernCards p {
  margin-bottom: 25px;
} */
.Services_Container .ModernCards:hover {
  /* box-shadow: 0px 2px 2px 0 rgb(0 0 0 / 0.5); */
  box-shadow: var(--boxShadow);
  padding-top: 20px;
  padding-bottom: 15px;
}
.Services_Container .ModernCards h1 {
  font-family: "Muli";
  font-size: 18px;
  letter-spacing: -1px;
  color: var(--text);
}
.Services_Container .ModernCards p {
  font-size: 16px;
  font-family: "Open sans" sans-serif;
  line-height: 50px;
  color: var(--text);
}
@media screen and (max-width: 425px) {
  .Services_Container .ModernClean {
    margin-bottom: unset;
  }
  .ServicesCard p {
    width: 95%;
  }
  .ServicesCard {
    /* width: 100% !important; */
    min-width: unset;
  }
  .Services_Container .ModernClean div {
    margin-bottom: 20px;
  }
}
