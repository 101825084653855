.client-says {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
}

.client-says .client-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.client-says .client-img img {
    width: 215px;
    height: 215px;
    border-radius: 100%;
}

.client-says .client-reviews .caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client-says .client-reviews .caption {
    padding-bottom: 48px;

}

.client-says .client-reviews .caption .ratings {
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-says .client-reviews .caption .ratings p {
    font-size: 24px;
    font-weight: 400;
    margin-right: 10px;
    letter-spacing: -1.2px;
    line-height: 28px;
    text-align: left;
    color: #17313b;
}

.client-says .client-reviews .caption .ratings svg {
    color: var(--primary);
}

.client-says .client-reviews .feedback h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.6px;
    text-align: left;
    color: #000;
    margin-bottom: 48px;
}

.client-says .client-reviews .feedback p {
    color: #2e384d;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: -1.2px;
    opacity: .5;
}

.client-says .client-reviews .feedback .client-intro h5 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.client-says .client-reviews .feedback .client-intro p {
    font-size: 1rem;
    letter-spacing: 0;
}

.blue-font {
    color: var(--primary);
    opacity: 1;
    cursor: pointer;
}

.clients .clients-slider {
    display: flex;
    justify-content: center;
    align-self: center;
}

@media (max-width: 1020px) {
    .client-says {
        flex-direction: column;
        gap: 20px;
    }

    .client-says .client-img img {
        width: 190px;
        height: 190px;
    }

    .client-says .client-reviews .caption img {
        height: 40px;
    }

    .client-says .client-reviews .feedback h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .client-says .client-reviews .feedback p {
        font-size: 25px;
    }
}

