.testimonial-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
}

.testimonial-container .stars{
    width: 100%;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-container svg{
    font-size: 40px;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--primary);
}

.testimonial-container h2{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    font-style: italic;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.testimonial-container .review{
    min-height: 222px;

}
.testimonial-container .review p{
    text-align: center;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 600;
}

.testimonial-container h5{
    text-align: center;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 600;
    min-height: 40px;
}

.testimonial-container h6{
    text-align: center;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 600;
    margin-top: 30px;
}
