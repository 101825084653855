.Blockchain .about_container h1 {
  line-height: 50px;
  color: white;
}
header-container {
  z-index: 0;
}
.blockchainMargin {
  position: relative;
}
.blockchainMargin h1 {
  color: white;
  margin-bottom: 10px;
}
.blockchainMargin .LoadMore  {
  background: transparent;
}
.blockchainMargin svg {
  color: white !important;
}
.Blockchain .about_container p {
  width: 90%;
  color: white;
}
.Blockchain .about_container button {
  color: white;

  font-family: "Muli";
  background-color: #4181d3;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  width: fit-content;
  border-radius: 3px;
  cursor: pointer;
}
.BlockchainCards {
  text-align: center;
  width: 90%;
}
.BlockchainCards h1 {
  font-size: 38px;
  font-family: "Muli";
  color: white;
}
.BlockchainCards p {
  font-size: 18px;
  font-family: "Open sans";
  font-weight: bold;
  color: white;
}
.BlockchainCards .BlockchainCard h1 {
  font-size: 22px;
  color: white;
}
.BlockchainCards .BlockchainCard p {
  font-size: 16px;
  font-weight: normal;
  color: white;
}
.BlockchainCards .BlockchainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 300px;
  border-radius: 5px;
  padding-top: 25px;
  padding-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: padding-bottom 1s;
  transition: padding-top 1s;
  /* -webkit-transition: all 0.3s ease; */
  transition: all 0.3s ease;
}
.BlockchainCards .BlockchainCard:hover {
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 15px;
}
.BlockchainCards .BlockchainCard p {
  width: 70%;
}
.Blockchain .BlockchainTemplates {
  width: 90%;
}
.Blockchain .BlockchainTemplates .template_div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Blockchain .BlockchainTemplates .template_div h1 {
  font-size: 36px;
  font-family: "Muli";
  color: white;
}

.Blockchain .BlockchainTemplates .template_div p {
  font-size: 16px;
  font-family: "Open sans";
  width: 90%;
  color: white;
}
.Blockchain .BlockchainTechnologies {
  width: 90%;
  text-align: center;
}
.Blockchain .BlockchainTemplates .leftImage {
  margin-right: 80px;
}
.Blockchain .BlockchainTechnologies h1 {
  margin-top: 100px;
  font-size: 32px;
  font-family: "Muli";
  color: white;
}
.Blockchain .BlockchainTechnologies p {
  font-family: "Open sans";
  font-weight: bolder;
  color: white;
}
.Blockchain .BlockchainTechnologies .backgroundImage {
  background-size: cover;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  gap: 10px;
}
.Blockchain .BlockchainTemplates .template_div .list_style {
  display: flex;
  justify-content: space-between;
  width: 85%;
  font-size: 15px;
  font-family: "Open sans";
  text-align: left;
  color: white;
}
.blockchainStyle {
  /* background-color: red; */
  min-height: 350px !important;
}
@media screen and (max-width: 1024px) {
  .blockchainStyle {
    min-height: 220px !important;
  }
}
@media screen and (max-width: 768px) {
  .Blockchain .mobileApp {
    align-items: center;
    margin-top: 50px;
  }
  .BlockchainCards h1 {
    font-size: 32px;
  }
  .BlockchainCards p {
    font-size: 16px;
  }
  .Blockchain .BlockchainTemplates .template_div {
    align-items: center;
    margin-top: 50px;
    text-align: center;
  }
  .Blockchain .BlockchainTemplates .leftImage {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .Blockchain .about_container h1 {
    text-align: center;
  }
  .Blockchain .about_container p {
    text-align: center;
  }
  .Blockchain .about_container h1 {
    font-size: 20px;
    line-height: 20px;
  }
  .Blockchain .about_container p {
    font-size: 13px;
  }
  .Blockchain .about_container button {
    padding: 10px 15px;
    font-size: 12px;
  }
  .BlockchainCards h1,
  .BlockchainCards .BlockchainCard h1,
  .Blockchain .BlockchainTemplates .template_div h1,
  .Blockchain .BlockchainTechnologies h1 {
    font-size: 20px;
  }
  .BlockchainCards p,
  .BlockchainCards .BlockchainCard p,
  .Blockchain .BlockchainTemplates .template_div p {
    font-size: 13px;
  }
  .Blockchain .BlockchainTemplates .template_div .list_style {
    font-size: 11px;
    width: 100%;
  }
  .blockchainStyle {
    min-height: 80px !important;
  }
}
