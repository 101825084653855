.Blog_container {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.Blog_container .BlogImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Blog_container .BlogImage img {
  width: 60%;
}
.Blog_container .BlogText {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.Blog_container .BlogText h1 {
  font-size: 30px;
  font-family: "Muli";
  color: var(--text);
}
.Blog_container .BlogText p {
  font-family: "Muli";
  margin-bottom: 20px;
  color: var(--text);
}
.Blog_container .BlogText h2 {
  font-family: "Open sans";
  font-size: 16px;
  color: rgb(173, 169, 169);
  color: var(--text);
}
@media screen and (max-width: 768px) {
  .Blog_container .BlogImage img {
    width: 100%;
  }
}
