.block-chain .solution {
  padding: 70px 0px;
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.block-chain .solution h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .solution p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .features {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F7FF;
}

.block-chain .features h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .features p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .development-process {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.block-chain .development-process h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .development-process p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .leading-choice {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F7FF;
}

.block-chain .leading-choice h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .leading-choice p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .testimonial {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff
}

.block-chain .testimonial h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .testimonial p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .faq {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff
}

.block-chain .faq h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.block-chain .contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.width85 {
  width: 85%;
}

.block-chain .waveImageStyle {
  margin-bottom: 0;
}

.block-chain .development-platforms {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
}

.block-chain .development-platforms h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.block-chain .development-platforms p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .development-platforms .development-platforms-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.block-chain .development-platforms .development-platforms-card img {
  width: 72px;
}

.block-chain .development-platforms .development-platforms-card h3 {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}