.portfolio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.portfolio-container .head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 250px;
}

.portfolio-container .head h2 {
    font-size: 72px;
    font-weight: 700;
}

.portfolio-container .domains {
    margin-top: 30px;
    width: 95%;
    margin-bottom: 30px;
}

.portfolio-container .connect {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    flex-direction: column;
}

.portfolio-container .connect h3 {
    font-size: 50px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    width: 820px;
}

.portfolio-container .connect p {
    text-align: center;
    width: 820px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.2em;
}

.portfolio-container .connect button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.portfolio-container .connect button:hover {
    color: #fff;
    background: #000;
}

@media (max-width: 770px) {
    .portfolio-container .connect h3 {
        width: 95%;
    }

    .portfolio-container .connect p {
        width: 95%;
    }

    .portfolio-container .head h2 {
        text-align: center;
    }

}