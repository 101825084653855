@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@500&family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #eaeaea;
}

.swal-text {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 1px;
  margin: 0;
}

.general-header {
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 17px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  width: 100%;
  z-index: 100;
  top: 0;
}
.general-header .Buttons {
  width: auto;
}
.general-header .Buttons button {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.general-header .Buttons button:first-child {
  margin-right: 10px;
}
.general-header .vendor-info {
  /* display: flex;
  align-items: center; */
  width: 30%;
}
.general-header a {
  width: 50%;
}
.general-header .vendor-info p {
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
}

.general-header h2 {
  color: var(--secondary-color);
  margin: 0;
  letter-spacing: 2px;
  font-weight: bold;
}

.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.m-20 {
  margin: 20px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 550px) {
  .general-header {
    flex-direction: column;
  }
  .general-header h2 {
    font-size: 20px;
    margin: 20px 0;
  }
  .general-header {
    align-items: flex-start;
  }
  .general-header .Buttons {
    align-items: flex-end;
    margin-left: auto;
  }
  .general-header .vendor-info p {
    font-size: 13px;
  }
}
/* Whatsapp chat  */
.FloatingButton__FloatingButtonContainer-sc-q5md4t-0{
  right: 80px !important;
};

.ant-btn-link {
  font-weight: bold !important;
  padding: 4px 0;
}
.ant-btn-primary {
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 2px;
  height: 40px;
}
.ant-input,
.ant-picker {
  height: 37px;
  border-radius: 10px;
}
.ant-form-item-label label {
  letter-spacing: 1px;
}
.ant-checkbox-wrapper {
  padding: 5px 0;
}
.ant-divider-vertical {
  /* height: auto; */
  /* border-left: 2px solid rgba(0, 0, 0, 0.06); */
  border-left: 1px solid rgba(138, 27, 106, 0.5);
}

.ant-pagination-total-text {
  display: flex;
  margin-right: auto;
}

.ant-modal-content {
  background-color: var(--cardColor) !important;
}
.ant-modal-close-x {
  color: var(--text) !important;
}
.ant-modal-header {
  background-color: var(--cardColor) !important;
}
.ant-modal-title {
  color: var(--text) !important;
}
.ant-form-item-label label {
  color: var(--text) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 37px;
  border-radius: 10px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 20px;
  color: var(--primary-color);
}
.ant-input {
  background-color: transparent !important;
  border: 1.6px solid var(--borderLight);
  border-radius: 4px;
  color: var(--text) !important;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent !important;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text{
  flex: auto !important;
}
.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar);
}

.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-switch-handle {
  top: 3px !important;
  width: 15px !important;
  height: 15px !important;
  left: 7px;
}
.ant-switch-checked .ant-switch-inner {
  margin-left: 3px !important;
}
.ant-drawer-content-wrapper {
  /* width: 100% !important; */
}
.v-layout .company-logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
}
.v-layout .company-logo img {
  width: 70%;
  height: 100px;
}
.v-layout .company-title {
  font-size: 22px;
  color: var(--text) !important;
  text-transform: uppercase;
}
.header-container {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: var(--background);
  z-index: 10;
}

.header-container .left {
  cursor: pointer;
  padding: 30px;
  width: 80%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}
.header-container .left img{
  height: 30px;
  width: 190px;
  margin-right: 60px;
}
.header-container .left h2 {
  font-family: 'Muli';
  font-size: 16px;
  width: -webkit-max-content;
  width: max-content;
  color: var(--textColor);
}
.header-container .header_btn {
  width: 20%;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: flex-end;
  padding: 20px;
  align-items: center;
}
.header-container h1 {
  font-size: 25px;
  font-weight: bolder;
  color: var(--text);
}
header-container h2 {
  font-size: 12px;
  font-weight: bold;
  color: var(--text);

}
.smallScreen_container {
  display: none;
}
.header_btn .btn {
  cursor: pointer;
  background-color: var(--primary);
  color: white;
  border: none;
  height: 38px;
  width: 120px;
  border-radius: 50px;
  font-family: "Muli";
  font-size: 12px;
  
}
@media(max-width : 1560px){
  .header-container .left h2{
    font-size: 11px
  }
}
@media(max-width : 1320px){
  .header-container .left img{
    margin-right: 0;
  }
}
@media(max-width:1220px){
  .header-container .left img{
    height: 20px;
    width: 100px;
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header-container {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .smallScreen_container {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
  }
}

.sidebar-menu {
  background-color: transparent !important;
  color: var(--text) !important;
  font-size: 18px;
}
.sidebar-menu svg {
  font-size: 20px !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-right: 4px solid var(--primary) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  span,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  svg {
  color: var(--siderbarActive) !important;
}

.mainContent {
  background-color: var(--background);
  min-height: 88vh;
  /* padding: 0.6rem 1.4rem; */
}
.ant-layout-header {
  background-color: var(--background) !important;
  min-height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 6;
  padding: 0 30px;
}
.v-layout {
  display: block;
}
.m-layout {
  display: none;
  background-color: white;
}
@media (max-width: 1070px) {
  .v-layout {
    display: none;
  }
  .m-layout {
    display: block;
  }
}

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background);
  padding: 25px 20px;
  /* padding-right: 14px; */
}
.mobile-header h1 {
  font-weight: bolder;
font-family: 'Roboto', sans-serif;
}
.m-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.m-header {
  background-color: var(--background) !important;
  color: var(--text);
}
.m-children {
  /* padding: 14px; */
  background-color: var(--background) !important;
  min-height: 89.5vh;
}
.backArrow {
  font-size: 20px;
  color: var(--text);
}
.m-drawer .logo {
  height: 20px;
}

@media (max-width: 800px) {
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
}

@media (max-width: 650px) {
  .sidebar-menu {
    padding-left: 0 !important;
  }
  .logout-menu {
    padding-left: 24px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.footer_container {
  /* background-color: var(--footerBright);
   */
  /* background: rgb(43, 126, 194);
  background: linear-gradient(
    90deg,
    rgba(43, 126, 194, 1) 0%,
    rgba(81, 190, 237, 1) 100%
  ); */
  background: rgb(3, 62, 111);
  background: linear-gradient(
    0deg,
    rgba(3, 62, 111, 1) 0%,
    rgba(81, 190, 237, 1) 100%
  );
  /* padding: 50px; */
  width: 90%;
}
.footer_container h1 {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: white;
}
.footer_container p {
  font-size: 16px;
  font-family: "Open sans" sans-serif;
  color: white;
}
.footer_container h3 {
  font-size: 16px;
  font-family: "Open sans" sans-serif;
  color: white;
}
.footer_container .footer_logo,
.quickLinks,
.tweet,
.appStore {
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
  grid-row-gap: 1rem;
  row-gap: 1rem;
  color: white;
  padding-top: 2rem;
}
.footer_container .quickLinks .icon_div {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.footer_container .quickLinks .icon_div svg {
  display: none;
  color: white;
  font-size: 20px;
}
.footer_container .quickLinks .icon_div:hover svg {
  display: block;
}
.footer_container .quickLinks p {
  cursor: pointer;
  color: white;
}
.footer_container h1,
h3 {
  color: white;
}
.footer_container .copyright {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.footer_container .copyright .icon {
  color: black;
  background-color: white;
  border-radius: 50%;
  margin: 10px;
  padding: 8px;
}
.footer_container .copyright p {
  font-size: 14px;
  font-family: "Open sans" sans-serif;
  color: white;
}
.footer_container .copyright .icon:hover {
  /* background-color: white;
   */
  cursor: pointer;
}
/* .waveImage {
  margin-bottom: -1px;
} */
.quickLinks .split_div {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  /* .waveImage {
    bottom: 875px;
  } */
  .waveImage {
    display: none;
  }
  .footer_container {
    margin: 15px 0;
  }
}
@media screen and (max-width: 768px) {
  /* .waveImage {
    bottom: 994px;
  } */
  .footer_container .copyright {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: unset;
  }
  .quickLinks {
    flex-direction: column;
    /* gap: 50px; */
  }
  .quickLinks .split_div {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
  }
  .appStore div {
    flex-direction: row;
    margin-top: 10px;
  }
  .footer_container .tweet,
  .footer_container .appStore,
  .footer_container .footer_logo {
    grid-gap: unset;
    gap: unset;
  }
  .footer_container .footer_row {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
  }
}
/* @media screen and (max-width: 375px) {
  .waveImage {
    bottom: 1015px;
  }
}
@media screen and (max-width: 320px) {
  .waveImage {
    bottom: 1065px;
  }
} */

.ant-table {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-table-thead > tr > th {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-spin-dot {
  font-size: 40px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 54%;
  left: 49%;
}
.ant-table .ant-table-expanded-row-fixed {
  min-height: 300px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-empty-img-simple-path,
.ant-empty-img-simple-ellipse {
  fill: var(--tableHeader) !important;
}
.ant-empty-description {
  color: var(--text) !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: var(--cardColor) !important;
}
.ant-pagination-prev .ant-pagination-item-link {
  background-color: transparent !important;
  color: var(--text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item {
  background-color: transparent !important;
}
.ant-pagination-item a {
  color: var(--text) !important;
}
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent !important;
  color: var(--text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item-active {
  background-color: transparent !important;
  color: var(--text) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  color: var(--text) !important;
}
.ant-select-arrow {
  color: var(--text) !important;
}
.ant-pagination-options {
  margin-right: 1rem;
}
/* .ant-tabs-top > .ant-tabs-nav::before , */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-color: var(--borderLight) !important;
}

.banner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
}

.banner .width75{
    margin-top: 10%;
}

.banner .banner-content h1{
    font-size: 72px;
    line-height: 72px;
    font-weight: 700;
    letter-spacing: -3.6px;
    margin-bottom: 1.22rem;
    color: #fff;
}

.banner .banner-content p{
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: -.4px;
    color: #fff;
}

.banner .bannerImage{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.banner .banner-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner .banner-btn button{
    min-width: 200px;
    height: 60px;
    color: #000;
    text-align: center;
    margin-bottom: 5.36rem;
    margin-top: 2.66rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    background: #fff;

}

.banner .banner-btn button:hover{
    color: #fff;
    background: #000;
}

.banner .features{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #2e384d;

}

.banner .features .features-cards{
    width: 11%;
    text-align: center;
    min-height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.banner .features .features-cards p{
    font-size: 17px;
    line-height: 21px;
    color: #fff;
}

.black-color{
    background-color: #000;
}

.width75{
    width: 75%;
  }
  
  .width85{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
  }

  .width65{
    width: 65%;
  }

  @media (max-width: 1320px){
    .banner .features .features-cards p{
        font-size: 12px;
    }
}

  @media (max-width: 1250px){
    .width75{
        width: 90%;
    }
    .banner .banner-content h1{
        font-size: 52px;
    }
  }


  @media (max-width: 980px){
.banner .features{
    display: none;
}
  }
  @media (max-width: 550px){
    .width75{
        width: 95%;
    }
    .banner .banner-content h1{
        font-size: 32px;
    }
  }
.client-says {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 70px;
    gap: 70px;
}

.client-says .client-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.client-says .client-img img {
    width: 215px;
    height: 215px;
    border-radius: 100%;
}

.client-says .client-reviews .caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client-says .client-reviews .caption {
    padding-bottom: 48px;

}

.client-says .client-reviews .caption .ratings {
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-says .client-reviews .caption .ratings p {
    font-size: 24px;
    font-weight: 400;
    margin-right: 10px;
    letter-spacing: -1.2px;
    line-height: 28px;
    text-align: left;
    color: #17313b;
}

.client-says .client-reviews .caption .ratings svg {
    color: var(--primary);
}

.client-says .client-reviews .feedback h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.6px;
    text-align: left;
    color: #000;
    margin-bottom: 48px;
}

.client-says .client-reviews .feedback p {
    color: #2e384d;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: -1.2px;
    opacity: .5;
}

.client-says .client-reviews .feedback .client-intro h5 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.client-says .client-reviews .feedback .client-intro p {
    font-size: 1rem;
    letter-spacing: 0;
}

.blue-font {
    color: var(--primary);
    opacity: 1;
    cursor: pointer;
}

.clients .clients-slider {
    display: flex;
    justify-content: center;
    align-self: center;
}

@media (max-width: 1020px) {
    .client-says {
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }

    .client-says .client-img img {
        width: 190px;
        height: 190px;
    }

    .client-says .client-reviews .caption img {
        height: 40px;
    }

    .client-says .client-reviews .feedback h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .client-says .client-reviews .feedback p {
        font-size: 25px;
    }
}


.main-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 20px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
}

.main-card .card-image {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-card .card-image img {
    max-width: 100%;
}

.main-card h3 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;

}

.main-card p {
    font-size: 16px;
    text-align: center;
    height: 250px;
}

.main-card button {
    color: #fff;
    background: var(--primary);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.main-card button:hover {
    background: #000;
    color: #fff;
}
@media (max-width: 1050px) {
    .main-card p {
        height: 290px;
    }
}

@media (max-width: 770px) {
    .main-card p {
        height: 230px;
    }
}
.testimonial-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
}

.testimonial-container .stars{
    width: 100%;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-container svg{
    font-size: 40px;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--primary);
}

.testimonial-container h2{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    font-style: italic;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.testimonial-container .review{
    min-height: 222px;

}
.testimonial-container .review p{
    text-align: center;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 600;
}

.testimonial-container h5{
    text-align: center;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 600;
    min-height: 40px;
}

.testimonial-container h6{
    text-align: center;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 600;
    margin-top: 30px;
}

.case-study-card{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 60px 40px;
    border-radius: 8px;
    margin: 30px 0;
    background: #fff;
}

.case-study-card h3{
    font-size: 22px;
    color: var(--primary);
    font-weight: 700;
    border-right: 4px solid var(--primary);

}

.case-study-card p{
    font-family: 18px;
    color: #000;
}

@media (max-width: 990px){
.case-study-card h3{
    border-right: none;
}
}

@media (max-width: 550px){
.case-study-card{
    padding: 30px 20px;
}
.case-study-card h3{
    text-align: justify;
}
.case-study-card p{
    text-align: justify;

}

}
.main-banner-container {
  background: var(--primary);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* min-height: 80vh; */
  min-height: 38rem;
  position: relative;
}

.main-banner-container .width75 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
}

.main-banner-container .heading {
  width: 49%;
}

.main-banner-container .heading h1 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 18px;
}
.main-banner-container .heading h3 {
  font-size: 37px;
  font-weight: 500;
  line-height: 1.35em;
}

.main-banner-container .heading .strong-underline {
  font-weight: 700;
  text-decoration: underline;
}

.main-banner-container .heading .consultation-call {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
}

.main-banner-container .heading .consultation-call .icon {
  background: #fff;
  border-radius: 7px;
  /* padding: 10px; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-banner-container .heading .consultation-call .icon svg {
  color: var(--primary);
  font-size: 20px;
}
.main-banner-container .heading .consultation-call h6 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 7px;
}

.main-banner-container .image {
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-banner-container .image img {
  width: 100%;
}
.main-banner-container .reviews-count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: absolute;
  bottom: -90px;
}

.main-banner-container .reviews-count .reviews-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  background: #fff;
  padding: 6px 10px;
  border-radius: 10px;
}

.main-banner-container .reviews-count .reviews-head .gmb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.main-banner-container .reviews-count .reviews-head .gmb img {
  width: 65px;
  margin-left: 20px;
}

.main-banner-container .reviews-count .reviews-head .gmb h3 {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 600;
}

.main-banner-container .reviews-count .reviews-head .gmb h6 {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  background-color: #f3f3f3;
  border-radius: 6px 6px 6px 6px;
}

.main-banner-container .reviews-count .reviews-head .stars {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-banner-container .reviews-count .reviews-head .stars img {
  margin-right: 20px;
  width: 120px;
}

.main-banner-container .reviews-count .ratings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  background: #fff;
  padding: 10px 40px;
}

.main-banner-container .reviews-count .ratings img {
  width: 78px;
}

@media (max-width: 1050px) {
    .main-banner-container{
        min-height: 48rem;
    }
  .main-banner-container .width75 {
    flex-direction: column;
    width: 90%;
  }
  .main-banner-container .heading {
    width: 100%;
  }
  .main-banner-container .image {
    width: 90%;
  }
  .main-banner-container .reviews-count .reviews-head {
    width: 90%;
  }
  .main-banner-container .reviews-count .ratings {
    width: 80%;
  }
}


@media(max-width: 550px) {
  .main-banner-container .reviews-count .ratings img{
    width: 30px;
  }
  .main-banner-container .reviews-count .reviews-head .gmb img{
    width: 40px;
  }
  .main-banner-container .reviews-count .reviews-head .gmb h3{
    font-size: 10px;
  }
  .main-banner-container .reviews-count .reviews-head .gmb h6{
    font-size: 10px;
  }
  .main-banner-container .reviews-count .reviews-head .stars img{
    width: 50px;
  }
}
.solution-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F5F7FF;
    padding: 10px 25px;
    min-height: 550px;
    background: #F5F7FF;
    border-radius: 10px;
}

.solution-card:hover {
    background: #fff;
    box-shadow: 0 10px 12px 0 rgba(0, 80.00000000000017, 255, .15);
}

.solution-card img {
    width: 90px;
}

.solution-card h3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: center;
    margin: 20px 0;
}

.solution-card p {
    text-align: center;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3em;
}
.feature-card{
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;
    padding: 15px 25px;
    min-height: 550px;
    background: #fff;
    border-radius: 10px;
}

.feature-card img{
    width: 90px;
}

.feature-card h3{
    color: #000;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: left;
    margin: 20px 0;
}

.feature-card p{
    text-align: left !important;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3em;
    margin: 0 !important;
}

.feature-card .link{
    color: var(--primary);
    font-size: 17px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: -0.5px;
}
.why-should{
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;
    padding: 10px 25px;
    min-height: 510px;
    background: #fff;
    border-radius: 10px;
}

.why-should h1{
    font-size: 45px;
    font-weight: 600;
    text-align: left;
    color: var(--primary);
}

.why-should h3{
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: left;
    margin: 20px 0;
}

.why-should p{
    text-align: left !important;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3em;
    margin: 0 !important;
}
.testimonial-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
    min-height: 320px;
    border-radius: 10px;
    background: #FFF;
    border: 1px solid #F1F1F1;;
    box-shadow: 0 10px 12px 0 rgba(0,80.00000000000017,255,.15);
}

.testimonial-card:hover{
    /* background: #fff; */

}
.testimonial-card img{
    width: 80px;
    border-radius: 50%;
}

.testimonial-card h3{
    color: #000;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: center;
    margin: 10px 0;
}

.testimonial-card .stars-image img{
    width: 100px;
    border-radius: 0;
    margin-bottom: 10px;

}
.testimonial-card p{
    text-align: center;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3em;
}
.timeline-right{
    margin-left: 20px;
}

.timeline-right h1{
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}

.timeline-right h4{
    color: #333;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.4em;
    text-align: left;

}

.timeline-left{
    margin-right: 20px;
}

.timeline-left h1{
    font-size: 22px;
    font-weight: 600;
    text-align: right;

}

.timeline-left h4{
    color: #333;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.4em;
    text-align: right;
}

.timeline-circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary);
}
.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 45%;
  margin: auto;
}
.signin .login-form {
  height: auto;
  margin: 20px 0;
  /* border: 1px #ddd solid; */
  box-shadow: var(--boxShadow);
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  width: 100%;
}
.signin .login-form img {
  margin: 0 auto;
  display: flex;
}

.signin .login-form h2 {
  font-weight: bold;
  text-align: center;
}
.signin .login-form button {
  width: 100%;
  height: 45px;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: var(--primary-color);
}
/* .signin .for-signup {
  text-align: center;
  width: 100%;
}
.signin .for-signup button {
  width: 100%;
  height: 40px;
  margin: 10px auto;
  border-color: #adb1b8 #a2a6ac #8d9096;
  border: 1px solid;
  background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
}

.signin .for-signup .signin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
}
.signin .for-signup .signin-btn svg {
  margin-right: 10px;
  font-size: 20px;
}
.signin .for-signup .signin-gf {
  display: flex;
} */

/* forget password  */
/* .signin-forget {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}
.signin-forget .forget-inner {
  width: 40%;
  height: 200px;
}
.signin-forget img,
.signin-forget h4,
.signup img,
.signup h4 {
  margin-bottom: 20px;
}
.for-signup h4 {
  margin: 10px 0;
}

.signin-forget p {
  margin-top: 20px;
  letter-spacing: 1px;
}
.signin-forget button {
  width: 100px;
  display: block;
  margin: 20px auto 0 auto;
} */

/* signup */

/* .signup {
  margin: 30px auto;
} */

/* media queries */

@media (max-width: 770px) {
  .signin,
  .signin-forget .forget-inner,
  .signup {
    width: 60%;
  }
}
@media (max-width: 550px) {
  .signin,
  .signup,
  .signin-forget .forget-inner {
    width: 90%;
  }
}

@font-face {
  font-family: "Muli";
  /* src: url('../../assets/fonts/Muli-Bold.ttf'); */
  src: url(/static/media/Muli-Bold.24ed2213.ttf);
}

.parent_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .text_container img {
  margin-top: 30px;
} */
::-webkit-scrollbar {
  display: none;
}
.techandapp_container .whiteWave {
  position: relative;
  bottom: 90px;
}
.techandapp_container .text_container div div {
  display: flex;
  flex-direction: column;
  grid-gap: 45px;
  gap: 45px;
}
.techandapp_container .TechAppCard h1 {
  font-family: "Mulish";
  font-size: 24px;
  letter-spacing: -1px;
  margin-top: 10px;
  font-weight: 900;
  color: var(--text);
}
.techandapp_container .text_container div div div {
  flex-direction: row;
}
.techandapp_container .text_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}
.TechAppCard p {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 28px;
  margin-top: 10px;
  color: var(--text);

  /* width: 95%; */
}
.techandapp_container .text_container .mobileApp .btn_div {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.techandapp_container .text_container .mobileApp .btn_div button {
  transition: all 0.3s ease;
}
.techandapp_container .text_container .mobileApp .btn_div button:hover {
  /* animation: tp-scaleout 1s infinite ease-in-out; */
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.15);
  /* margin: 2px 0; */
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
}
.techandapp_container .DesignCard .icon_div {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.techandapp_container .DesignCard .icon {
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 20px;
}
.techandapp_container .DesignCard .icon:hover {
  /* position: relative;
  left: 5px; */
  padding-left: 4px;
  font-size: 25px;
}
.textStyle-More {
  /* background: rgb(255, 255, 255); */
  /* background: linear-gradient(
    180deg,
    rgba(255,255,0,0) 70%
     rgb(255,255,200) ,
  ); */
  background: linear-gradient(
    transparent 67%,
    #00b3eb 67%,
    #00b3eb 90%,
    var(--background) 90%,
    var(--background) 100%
  );
}
.textStyle-Clients {
  /* background: rgb(255, 255, 255); */
  /* background: linear-gradient(
    180deg,
    rgba(255,255,0,0) 70%
     rgb(255,255,200) ,
  ); */
  background: linear-gradient(
    transparent 67%,
    #00b3eb 67%,
    #00b3eb 90%,
    var(--background) 90%,
    var(--background) 100%
  );
}
.blockchain_textStyle {
  background: linear-gradient(
    transparent 67%,
    #00b3eb 67%,
    #00b3eb 90%,
    transparent 90%,
    transparent 100%
  );
}
.Parallax_image {
  height: 80vh;
}
.waveImageStyle {
  /* margin-bottom: -150px; */
}
.slick-dots li button {
  background-color: gray !important;
}
@media screen and (max-width: 768px) {
  .techandapp_container .text_container .mobileApp {
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-items: self-start; */
    /* margin-top: 100px; */
    text-align: center;
    /* margin: 100px 0; */
  }
  .form_container {
    margin-bottom: 10px !important;
  }
  .Parallax_image {
    height: 80vh;
  }
  .statistic_container {
    height: 420px !important;
  }
  .techandapp_container .analysisCards {
    padding: 50px 0 !important;
  }
  .techandapp_container .whiteWave {
    display: none;
  }
  .parent_container .techAppCard_container .techAppCard_child {
    margin-bottom: 30px;
  }
  .techandapp_container .plansCards_conatiner {
    align-items: center !important;
    margin: 50px 0;
  }
  .appDevelopment_container .img {
    display: none;
  }
  .techandapp_container .DesignCard img {
    width: 100% !important;
    height: unset !important;
  }
  .heading {
    margin: unset !important;
  }
}
@media screen and (max-width: 425px) {
  .techandapp_container .analysisCards {
    display: unset !important;
  }
  .techandapp_container .text_container h1 {
    font-size: 25px !important;
    text-align: left;
    width: 100% !important;
    color: var(--text);
  }
  .techandapp_container .text_container p {
    width: unset !important;
    font-size: 14px !important;
    text-align: left;
    color: var(--text);
  }
  .techandapp_container .text_container .btn_div {
    flex-direction: row;
    margin-top: 10px;
  }
  .techandapp_container .text_container div div {
    grid-gap: unset;
    gap: unset;
  }
  .plansCards_conatiner h1 {
    font-size: 24px !important;
    color: var(--text);
  }
  .plansCards_conatiner p {
    font-size: 14px !important;
    color: var(--text);
  }
  .techandapp_container .progress h1 {
    font-size: 24px !important;
    color: var(--text);
  }
  .techandapp_container .progress p {
    font-size: 14px !important;
    font-weight: normal !important;
    color: var(--text);
  }
  .appDevelopment_container .FormText .heading {
    font-size: 24px !important;
  }
  .appDevelopment_container .FormText p {
    font-size: 14px !important;
    color: var(--text);
  }
  /* .techandapp_container .text_container div div div {
    flex-direction: column;
  } */
  .techandapp_container .statistic_container .main .allStats {
    width: 200px !important;
    height: 300px !important;
  }
  .techandapp_container .statistic_container .main .salesGraf {
    width: 200px !important;
    height: 200px !important;
  }
  .techandapp_container .statistic_container .main .group {
    width: 100px !important;
    height: 100px !important;
    right: 40px !important;
  }
  /* .FormText .contact {
    display: unset !important;
  } */
  .FormText .contact .pakInfo .location .icon svg{
    font-size: 20px;
  }
  .FormText .address {
    display: unset !important;
  }
  .techandapp_container .plansCards_conatiner {
    align-items: unset !important;
  }
  .Parallax_image {
    height: 38vh;
  }
  .statistic_container {
    height: 300px !important;
  }
}
.techandapp_container .text_container h1 {
  /* font-size: 72px; */
  font-size: 32px;
  width: 500px;
  font-weight: 800;
  line-height: 40px;
  font-family: "Muli";
  color: var(--text);
}
.techandapp_container .text_container p {
  /* font-size: 20px; */
  font-size: 16px;
  /* width: 570px; */
  /* font-family: "Roboto", sans-serif; */
  font-family: "Open Sans", sans-serif;
  color: #444444;
  color: var(--text);
}
.techandapp_container .techAppCard_container {
  width: 93%;
  padding: 40px;
  box-shadow: 0px 15px 44px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 20px;
  gap: 20px;
  /* margin-bottom: 50px; */
}
.parent_container .appDevelopment_container {
  width: 90%;
}
.plansCards_conatiner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 30px;
  gap: 30px;
  /* font-family: Open Sans; */
}
.plansCards_conatiner button {
  transition: all 0.3s ease;
}
.plansCards_conatiner button:hover {
  /* transform: translateY(-0.25em); */
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
}
.plansCards_conatiner h1 {
  font-size: 32px;
  font-weight: 800;
  font-family: "Muli";
  letter-spacing: -1px;
  color: var(--text);
}
.plansCards_conatiner p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: var(--text);
}
.techandapp_container .analysisCard_container {
  border: 1px solid #eeeded;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
  transition: all 0.3s ease;
}
.techandapp_container .analysisCard_container:hover {
  box-shadow: 0px 15px 33px 0 rgb(0 0 0 / 14%);
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
}
.techandapp_container .analysisCard_container h2 {
  font-family: "Muli";
  font-size: 18px;
  letter-spacing: -1px;
  color: var(--text);
}
.techandapp_container .analysisCard_container p {
  font-family: "Open sans" sans-serif;
  font-size: 14px;
  color: var(--text);
}
/* .techandapp_container .analysisCards div {
  display: flex;
  flex-direction: column;
  gap: 20px;
} */
.techandapp_container .analysisCards {
  padding: 100px 0;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.techandapp_container .analysisCards .analysisCards_child {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 30px;
}
.techandapp_container .analysisCards .icon {
  /* background-color: #ffbd4b; */
  padding: 10px;
  border-radius: 50%;
  color: white;
}
.techandapp_container .progress {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.techandapp_container .progress h1 {
  font-size: 32px;
  font-weight: 800;
  font-family: "Muli";
  letter-spacing: -1px;
  color: var(--text);
}
.techandapp_container .progress p {
  /* color: #5a73c6; */
  font-size: 14px;
  font-weight: 800;
  font-family: "Open sans" sans-serif;
  color: var(--text);
}
/* .techandapp_container .progress div {
  margin-bottom: 5px;
} */
.techandapp_container .progress div div {
  display: flex;
  justify-content: space-between;
}
.statistic_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
}
.statistic_container .main {
  position: relative;
  width: 600px; /*here is the changes */
  height: 600px; /*here is the changes */
  display: flex;
  align-items: center;
  justify-content: center;
}
.statistic_container .main .group {
  position: absolute;
  width: 300px;
  height: 300px;
  right: 10px;
}
.statistic_container .main .salesGraf {
  position: absolute;
  left: 13px;
  /* top: 55px; */
  width: 400px;
  height: 400px;
}
.statistic_container .main .allStats {
  position: absolute;
  /* top: 0px; */
  width: 400px;
  height: 500px;
}
.appDevelopment_container .FormText h1 {
  font-size: 15px;
  color: var(--text);
}
.appDevelopment_container .FormText .heading {
  font-size: 32px;
  font-family: "Muli";
  letter-spacing: -1px;
}
.appDevelopment_container .FormText p {
  /* font-size: 16px; */
  font-family: "Open sans" sans-serif;
  color: var(--text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.appDevelopment_container .FormText p a{
  color: var(--text);
  cursor: pointer;
}
.appDevelopment_container .FormText svg{
  margin-right: 10px;
}
.appDevelopment_container .FormText h1 {
  font-family: "Muli";
  font-size: 20px;
  color: var(--text);
}
.appDevelopment_container .form_container h1 {
  font-family: "Muli";
  font-size: 32px;
  color: var(--text);
}
.appDevelopment_container .form_container textarea {
  padding: 20px;
}
.appDevelopment_container .form_container button {
  font-family: "Muli";
  font-size: 12px;
  background-color: var(--primary);
  color: white;
  padding: 22px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appDevelopment_container .form_container button:hover {
  background-color: black;
}
.ProgressChart {
  margin-bottom: 50px;
}
@media screen and (max-width: 500px) {
  .techandapp_container .text_container h1 {
    width: unset;
  }
}
@media screen and (max-width: 1024px) {
  .statistic_container .main .group {
    width: 200px;
    height: 200px;
  }
  .waveImageStyle {
    /* margin-bottom: -120px; */
  }
  .statistic_container .main .salesGraf {
    width: 300px;
    height: 300px;
  }
  .statistic_container .main .allStats {
    width: 300px;
    height: 400px;
  }
}
.techandapp_container .analysisCard_container {
  min-height: 210px;
  flex-direction: column;
}
.techandapp_container .DesignCard {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.techandapp_container .DesignCard a {
  font-size: 11px;
  color: black;
  font-family: "Muli";
}
.techandapp_container .DesignCard h1 {
  font-weight: 800;
  font-family: "Muli";
  font-size: 22px;
  letter-spacing: -1px;
}
.techandapp_container .DesignCard h1:hover,
.techandapp_container .DesignCard a:hover {
  color: #f9b94a !important;
}
.techandapp_container .DesignCard img {
  width: 350px;
  height: 200px;
}
.FormText {
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
  grid-gap: 20px;
  gap: 20px;
}
.FormText h1 {
  font-weight: 800;
}
.FormText .address {
  display: flex;
  justify-content: space-between;
}
.FormText .contact {
  display: flex;
  justify-content: space-between;
}
.FormText .contact .pakInfo{
  margin-top: 1rem;
}
.FormText .contact .icon {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  /* margin-right: 50px; */
}
.FormText .contact .icon div {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.FormText .contact .icon div svg:hover {
  color: var(--primary);
  cursor: pointer;
}
.form_container input,
Textarea {
  border-radius: 20px;
}
.form_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 100px;
}
.form_container .textArea {
  border-radius: 20px;
}
.form_container div {
  width: 100%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.techandapp_container .heading {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1px;
}
span {
  /* z-index: 2; */
  /* mix-blend-mode: multiply; */
}
span .textStyle {
  /* z-index: -1; */
  top: 90px;
  position: relative;
  background-color: aqua;
  width: 240px;
  height: 30px;
}
@media screen and (max-width: 425px) {
  .waveImageStyle {
    /* margin-bottom: -50px; */
  }
  .statistic_container .main .group {
    left: 210px;
  }
  .statistic_container .main .salesGraf {
    right: 113px;
    left: unset;
  }
  .techandapp_container .progress {
    padding-bottom: 20px;
  }
  .ProgressChart {
    margin-bottom: 20px !important;
  }
  .main,
  .statistic_container {
    display: none !important;
  }
}
@media screen and (max-width: 375px) {
  /* .statistic_container .main .group {
    left: 155px;
  } */
  /* .statistic_container .main .salesGraf {
    right: 70px;
  } */
  .statistic_container .main .group {
    left: 155px;
  }
  .statistic_container .main .salesGraf {
    right: 70px;
  }
}
@media screen and (max-width: 320px) {
  .techandapp_container .statistic_container .main .group {
    right: 10px !important;
  }
  .Parallax_image {
    height: 54vh;
  }
  .statistic_container .main .group {
    left: 104px;
  }
  .statistic_container .main .salesGraf {
    right: 10px;
  }
  .statistic_container .main .allStats {
    width: 240px;
    height: 340px;
  }
}

.parent_container .about_container {
  width: 90%;
}
.parent_container .about_container h1 {
  font-family: "Muli";
  font-size: 48px;
  font-weight: bolder;
  letter-spacing: -1px;
  color: var(--text);
}
.parent_container .about_container p {
  font-family: "Open sans" sans-serif;
  font-size: 18px;
  color: var(--text);
}
.parent_container .AboutAnalysis {
  width: 90%;
  margin: 100px 0;
}
.parent_container .AboutAnalysis .AboutTextCard {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}
.parent_container .AboutAnalysis .AboutTextCard .icon_div,
.about_icon_div {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.parent_container .AboutAnalysis .AboutTextCard .icon_div a,
.about_icon_div a {
  font-family: "Muli";
  font-size: 12px;
}

.parent_container .AboutAnalysis .AboutTextCard a {
  font-weight: bold;
  color: var(--text);
}
.parent_container .AboutAnalysis .AboutTextCard .icon,
.about_icon_div .about_icon {
  color: blue;
  font-size: 20px;
  transition: all 0.3s ease;
}
.parent_container .AboutAnalysis .AboutTextCard .icon:hover {
  padding-left: 5px;
  font-size: 25px;
  /* position: relative;
  left: 5px; */
}
.parent_container .AboutAnalysis .AboutTextCard h1 {
  font-family: "Muli";
  font-size: 28px;
  font-weight: bolder;
  text-align: left;
  line-height: 1.5;
  letter-spacing: -1px;
  color: var(--text);
}
.parent_container .AboutAnalysis .AboutTextCard p {
  font-family: "Open sans" sans-serif;
  font-size: 16px;
  color: var(--text);
}
.parent_container .RoundProgress {
  width: 90%;
  margin: 100px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}
.RoundProgress .ant-progress-text {
  color: var(--text) !important;
}
.parent_container .RoundProgress h1 {
  font-family: "Muli";
  font-size: 20px;
  letter-spacing: -1px;
  color: var(--text);
}
.parent_container .RoundProgress p {
  font-family: "Open sans" sans-serif;
  font-size: 16px;
  color: var(--text);
}
.StartYourWebsite_container {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(254, 210, 123, 0.37);
}
.StartYourWebsite_container .StartYourWebsite {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.RoundProgress .ant-progress-circle .ant-progress-text {
  font-weight: 700;
  font-size: 64px !important;
}
.StartYourWebsite_container .StartYourWebsite h1 {
  font-family: "Muli";
  font-size: 32px;
  font-weight: bold;
  color: var(--text);
}
.StartYourWebsite_container .StartYourWebsite button {
  font-family: "Roboto" sans-serif;
  font-size: 12px;
  background-color: var(--primary);
  color: white;
}
.parent_container .about_container .mobileApp {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .parent_container .about_container .mobileApp {
    text-align: center;
    /* margin: 100px 0; */
  }
}
@media screen and (max-width: 425px) {
  .StartYourWebsite_container .StartYourWebsite {
    text-align: center;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }
  .parent_container .RoundProgress {
    margin: 50px 0;
  }
  .statistic_container {
    height: 300px !important;
  }
  .parent_container .about_container h1 {
    text-align: left;
    font-size: 28px;
  }
  .parent_container .about_container p {
    font-size: 14px;
    text-align: left;
  }
  .parent_container .AboutAnalysis {
    margin: unset;
  }
  .parent_container .AboutAnalysis .AboutTextCard h1 {
    font-size: 24px;
  }
  .parent_container .AboutAnalysis .AboutTextCard p {
    font-size: 14px;
  }
  .AboutAnalysis {
    margin-bottom: unset !important;
  }
}

.parent_container .DataCards {
  width: 90%;
  text-align: center;
  margin: 100px 0;
  background-color: var(--background);
}
.ServicesCard {
  /* padding: 20px; */
  /* margin-bottom: 50px; */
  /* background-color: red; */
  cursor: pointer;
  min-height: 325px;
  min-width: 325px;
  /* margin: 20px; */
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: padding-bottom 1s;
  transition: padding-top 1s;
  transition: all 0.3s ease;
}
.ServicesCard:hover {
  box-shadow: var(--boxShadow);
  /* transform: translate(10px, 10px); */
  /* padding: 22px; */
  /* animation: postion-change normal 1s linear; */
  padding-top: 20px;
  padding-bottom: 15px;
  /* margin-bottom:55px; */
  /* background-color : blue */
}
.ServicesCard h1 {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--text);
}
.ServicesCard p {
  font-family: "Open Sans" sans-serif;
  font-size: 14px;
  color: var(--text);
}

/* @keyframes postion-change {
  from {transition: rotate(0deg);}
  to {transition: rotate(1deg);}

} */

.Services_Container .ModernClean {
  width: 90%;
  margin: 100px 0;
}
.Services_Container .ModernClean div {
  min-height: 400px;
}
.Services_Container .ModernCards {
  min-height: 300px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  text-align: center;
  padding: 10px;
  /* padding-top: 25px;
  padding-bottom: 5px;
  transition: padding-bottom 1s;
  transition: padding-top 1s; */
  transition: all 0.3s ease;
  /* padding: 50px 20px; */
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
/* .Services_Container .ModernCards h1,
svg {
  margin-bottom: 25px;
}
.Services_Container .ModernCards p {
  margin-bottom: 25px;
} */
.Services_Container .ModernCards:hover {
  /* box-shadow: 0px 2px 2px 0 rgb(0 0 0 / 0.5); */
  box-shadow: var(--boxShadow);
  padding-top: 20px;
  padding-bottom: 15px;
}
.Services_Container .ModernCards h1 {
  font-family: "Muli";
  font-size: 18px;
  letter-spacing: -1px;
  color: var(--text);
}
.Services_Container .ModernCards p {
  font-size: 16px;
  font-family: "Open sans" sans-serif;
  line-height: 50px;
  color: var(--text);
}
@media screen and (max-width: 425px) {
  .Services_Container .ModernClean {
    margin-bottom: unset;
  }
  .ServicesCard p {
    width: 95%;
  }
  .ServicesCard {
    /* width: 100% !important; */
    min-width: unset;
  }
  .Services_Container .ModernClean div {
    margin-bottom: 20px;
  }
}

.clients_container .ourClients {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  text-align: center;
}
.clients_container .ourClients h1 {
  font-family: "Muli";
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -1px;
  color: var(--text);
}
.clients_container .ourClients h2 {
  font-family: "Muli";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  color: var(--text);
}
.clients_container .ourClients p {
  font-family: "Open sans";
  width: 50%;
  font-size: 16px;
  color: var(--text);
}
.clients_container .brands_container {
  width: 90%;
  text-align: center;
  /* margin-bottom: 100px; */
}
.brands_container .img {
  height: 70px;
  -webkit-filter: grayscale(60%);
          filter: grayscale(60%);
}
.brands_container .brand {
  height: 60px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.clients_container .brands_container .image_container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clients_container .brands_container img {
  /* -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; */
}
.clients_container .brands_container img:hover {
  padding: 5px;
  transition: 0.3s ease-in;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
@media screen and (max-width: 768px) {
  .brands_container .brand {
    height: 30px;
  }
  .clients_container .brands_container .image_container {
    min-height: 110px;
  }
  .clients_container .brands_container {
    margin: 50px 0;
  }
}
@media screen and (max-width: 425px) {
  .clients_container .ourClients {
    padding: 10px;
  }
  .clients_container .ourClients h1 {
    font-size: 30px;
  }
  .clients_container .ourClients h2 {
    font-size: 20px;
  }
  .clients_container .ourClients p {
    width: 100%;
  }
  .brands_container .brand {
    height: 40px;
  }
  .brands_container .img {
    height: 50px;
  }
  .clients_container .brands_container .image_container {
    min-height: 90px;
  }
}

.blogGrid_container .blogGridText_Container {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bolder;
  font-family: "Muli";
  letter-spacing: -1px;
}
.blogGrid_container .blogGridText_Container h1 {
  color: var(--text);
}
.LoadMore {
  background-color: var(--background);
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  /* margin: 50px 0; */
}
.LoadMore h1 {
  font-family: "Muli";
  font-weight: bold;
  font-size: 12px;
  color: var(--text);
}
.LoadMore .icon {
  font-size: 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  color: var(--text);
}
.LoadMore .icon:hover {
  /* margin-top: 10px; */
  padding-top: 10px;
  font-size: 30px;
  /* position: relative;
  top: 10px; */
}
.blogGrid_container .gridCards_container {
  width: 90%;
  margin-bottom: 100px;
}
.blogGrid_container .gridCards_container .DesignCard a {
  font-size: 12px;
  color: var(--text);
}
.blogGrid_container .gridCards_container .DesignCard h1 {
  font-size: 22px;
  letter-spacing: -1px;
  color: var(--text);
}
.blogGrid_container .gridCards_container .DesignCard {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.blogGrid_container .gridCards_container .DesignCard .icon_div {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.blogGrid_container .gridCards_container .DesignCard .icon {
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 20px;
}
.blogGrid_container .gridCards_container .DesignCard .icon:hover {
  padding-left: 5px;
  font-size: 25px;
}
.blogGrid_container .gridCards_container img {
  width: 100%;
}
.blogGrid_container .gridCards_container h1 {
  font-family: "Muli";
  color: var(--text);
}
.blogGrid_container .gridCards_container p {
  font-family: "Open sans";
  color: var(--text);
}
.blogGrid_container .gridCards_container a {
  font-family: "Muli";
  /* color: black; */
  font-size: 10px;
  color: var(--text);
}
@media screen and (max-width: 425px) {
  .blogGrid_container .blogGridText_Container {
    font-size: 28px;
    padding: 50px;
  }
  .blogGrid_container .gridCards_container {
    margin-bottom: unset;
  }
}

.custom404_container {
  /* height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; */
  /* height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif; */
}
.custom404_container h1,
h2 {
  font-family: "Muli";
}
.custom404_container p {
  font-family: "Muli";
}
.bg_image {
  /* The image used */
  background-image: url(/static/media/development.959a9975.jpg);
  background-color: rgba(221, 215, 215, 0.46);
  opacity: 0.96;
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.custom404_container h1 {
  font-size: 160px;
  font-weight: bolder;
  /* position: relative; */
  color: white;
}
.custom404_container h2 {
  font-size: 40px;
  font-weight: bolder;
  color: white;
}
.custom404_container p {
  font-size: 10px;
  text-align: center;
}
.custom404_container .icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .bg_text {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .custom404_container h1 {
    font-size: 160px;
    font-weight: unset;
  }
  .custom404_container h2 {
    font-size: 40px;
    font-weight: unset;
  }
}
@media screen and (max-width: 425px) {
  .custom404_container h1 {
    font-size: 50px;
    font-weight: unset;
  }
  .custom404_container h2 {
    font-size: 20px;
    font-weight: unset;
  }
}

.headerDrawer_container {
  /* padding: 10px; */
  /* background-color: var(--background); */

}
.HeaderDrawer .ant-drawer-body {
  background-color: var(--background) !important;
}
.headerDrawer_container .headerDrawer_links {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.headerDrawer_logo div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerDrawer_logo div svg {
  display: none;
}
.headerDrawer_container .headerDrawer_links p {
  cursor: pointer;
  /* font-weight: bold; */
  font-size: 20px;
  font-family: "Open sans" sans-serif;
  letter-spacing: -1px;
  color: var(--text);
}
/* .headerDrawer_container .headerDrawer_logo h1 {
  font-family: "Muli";
  font-size: 48px;
} */
.headerDrawer_container .headerDrawer_logo p {
  font-family: "Open sans";
  font-size: 14px;
  margin-top: 20px;
  color: var(--text);

}
.headerDrawer_container .line {
  background-color: #cecece;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}
.headerDrawer_container .contacts p {
  font-size: 14px;
  color: var(--text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.headerDrawer_container .contacts svg {
  margin-right: 10px;
}

.ant-drawer-header {
  border-bottom: none !important;
  width: 30%;
  display: none !important;
  top: 13px !important;
  position: absolute !important;
  left: 300px !important;
}
.ant-drawer-header-title {
  justify-content: flex-end !important;
}
@media screen and (max-width: 425px) {
  /* .ant-drawer-header {
    display: block !important;
  } */
  .headerDrawer_logo div svg {
    display: block;
  }
}

.dropDown_container {
  margin: 20px;
  padding: 20px;
  width: 200px;
  border-radius: 10px;
}
.dropDown_container a {
  font-weight: bold;
  font-size: 15px;
  font-family: 'Muli' !important;
}

.TeamCard_container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* margin-bottom: 50px; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  min-height: 250px;
  min-width: 100%;
  /* margin: 20px; */
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 5px;
  transition: padding-bottom 1s;
  transition: padding-top 1s;
  transition: all 0.3s ease;
}
.TeamCard_container:hover {
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  /* transform: translate(10px, 10px); */
  /* padding: 22px; */
  /* animation: postion-change normal 1s linear; */
  padding-top: 20px;
  padding-bottom: 15px;
}
.TeamCard_container img {
  border-radius: 50%;
}
.TeamCard_container h1 {
  font-family: "Muli";
}
.TeamCard_container p {
  font-family: "Open sans" sans-serif;
  color: rgb(131, 131, 131);
}
.team_container {
  margin-bottom: 10px;
}
.heading {
  font-size: 32px;
  margin: 50px 0;
}
@media screen and (max-width: 425px) {
  .team_container {
    margin-bottom: 80px;
  }
}

.portfolio_text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px;
  font-size: 32px;
  font-family: "Muli";
  word-spacing: -1px;
  color: var(--text);
}
.portfolio_text h1 {
  color: var(--text);
}
.PortfolioData {
  width: 90%;
}
.PortfolioData .filter {
  display: flex;
  justify-content: space-between;
}
.PortfolioData .filter p {
  font-family: "Muli";
  font-weight: bold;
  cursor: pointer;
  color: var(--text);
}
.PortfolioData .filter div {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.PortfolioData .PortfolioCard {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 280px;
  /* height: 200px; */
  background-size: contain;
  background-repeat: no-repeat;
  /* display: flex;
  justify-content: center;
  align-items: flex-end; */
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
}
.PortfolioData .PortfolioCard:hover img {
  transition: 0.3s ease-in;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.PortfolioData .PortfolioCard img {
  width: 100%;
  height: 100%;
}
.PortfolioData .PortfolioCard .text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--text);
}
.PortfolioData .PortfolioCard .text h1 {
  color: var(--text);
  text-shadow: var(--textShadow);
  visibility: hidden;
}
.PortfolioData .PortfolioCard .text p {
  visibility: hidden;
  text-shadow: var(--textShadow);
  color: var(--text);
}
.PortfolioData .PortfolioCard:hover h1,
.PortfolioData .PortfolioCard:hover p {
  visibility: visible;
  color: var(--text);
}
@media screen and (max-width: 425px) {
  .portfolio_text {
    font-size: 16px;
    padding: 50px;
  }
  .PortfolioData .filter {
    font-size: 10px;
  }
}

.PortfolioSummery {
  width: 90%;
  /* display: flex;
  gap: 50px; */
}
.PortfolioSummery .PortfolioText {
  margin-top: 50px;
  /* width: 30vw; */
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 100px;
  /* position: sticky; */
}
/* .PortfolioSummery .PortfolioText h1 {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--text);
} */
.PortfolioSummery .PortfolioText a {
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--text);
}
.PortfolioSummery .PortfolioText a:hover{
  cursor: pointer;
  color: var(--primary);
}
.PortfolioSummery .PortfolioText p {
  font-size: 16px;
  font-family: "Open sans";
  color: var(--text);
}
.PortfolioSummery .PortfolioText .category {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.PortfolioSummery .PortfolioText .social {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.PortfolioSummery .PortfolioText .social div {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 50px 0;
}
.PortfolioSummery .PortfolioText .category h2,
.PortfolioSummery .PortfolioText .social h2 {
  font-family: "Muli";
  font-size: 16px;
  color: var(--text);

}

.PortfolioSummery .PortfolioText .category li {
  font-size: 16px;
  font-family: "Open sans";
  color: rgb(124, 124, 124);
  color: var(--text);

}
.PortfolioSummery .PortfolioText .category div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.PortfolioSummery .PortfolioImages {
  /* width: 70vw; */
}
/* .slick-dots {
  background-color: rgb(0, 0, 0) !important;
  height: 10px !important;
  width: 20px !important;
} */

.carousal_Style {
  /* height: 500px; */
  width: 100%;
  color: #fff;
  line-height: 160px;
  text-align: center;
  /* //   background: #364d79; */
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .carousal_Style {
    height: 300px !important;
  }
}
@media screen and (max-width: 425px) {
  .carousal_Style {
    height: 200px !important;
  }
}

.Blog_container {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.Blog_container .BlogImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Blog_container .BlogImage img {
  width: 60%;
}
.Blog_container .BlogText {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.Blog_container .BlogText h1 {
  font-size: 30px;
  font-family: "Muli";
  color: var(--text);
}
.Blog_container .BlogText p {
  font-family: "Muli";
  margin-bottom: 20px;
  color: var(--text);
}
.Blog_container .BlogText h2 {
  font-family: "Open sans";
  font-size: 16px;
  color: rgb(173, 169, 169);
  color: var(--text);
}
@media screen and (max-width: 768px) {
  .Blog_container .BlogImage img {
    width: 100%;
  }
}

.Blockchain .about_container h1 {
  line-height: 50px;
  color: white;
}
header-container {
  z-index: 0;
}
.blockchainMargin {
  position: relative;
}
.blockchainMargin h1 {
  color: white;
  margin-bottom: 10px;
}
.blockchainMargin .LoadMore  {
  background: transparent;
}
.blockchainMargin svg {
  color: white !important;
}
.Blockchain .about_container p {
  width: 90%;
  color: white;
}
.Blockchain .about_container button {
  color: white;

  font-family: "Muli";
  background-color: #4181d3;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  cursor: pointer;
}
.BlockchainCards {
  text-align: center;
  width: 90%;
}
.BlockchainCards h1 {
  font-size: 38px;
  font-family: "Muli";
  color: white;
}
.BlockchainCards p {
  font-size: 18px;
  font-family: "Open sans";
  font-weight: bold;
  color: white;
}
.BlockchainCards .BlockchainCard h1 {
  font-size: 22px;
  color: white;
}
.BlockchainCards .BlockchainCard p {
  font-size: 16px;
  font-weight: normal;
  color: white;
}
.BlockchainCards .BlockchainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  min-height: 300px;
  border-radius: 5px;
  padding-top: 25px;
  padding-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: padding-bottom 1s;
  transition: padding-top 1s;
  /* -webkit-transition: all 0.3s ease; */
  transition: all 0.3s ease;
}
.BlockchainCards .BlockchainCard:hover {
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 15px;
}
.BlockchainCards .BlockchainCard p {
  width: 70%;
}
.Blockchain .BlockchainTemplates {
  width: 90%;
}
.Blockchain .BlockchainTemplates .template_div {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.Blockchain .BlockchainTemplates .template_div h1 {
  font-size: 36px;
  font-family: "Muli";
  color: white;
}

.Blockchain .BlockchainTemplates .template_div p {
  font-size: 16px;
  font-family: "Open sans";
  width: 90%;
  color: white;
}
.Blockchain .BlockchainTechnologies {
  width: 90%;
  text-align: center;
}
.Blockchain .BlockchainTemplates .leftImage {
  margin-right: 80px;
}
.Blockchain .BlockchainTechnologies h1 {
  margin-top: 100px;
  font-size: 32px;
  font-family: "Muli";
  color: white;
}
.Blockchain .BlockchainTechnologies p {
  font-family: "Open sans";
  font-weight: bolder;
  color: white;
}
.Blockchain .BlockchainTechnologies .backgroundImage {
  background-size: cover;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  grid-gap: 10px;
  gap: 10px;
}
.Blockchain .BlockchainTemplates .template_div .list_style {
  display: flex;
  justify-content: space-between;
  width: 85%;
  font-size: 15px;
  font-family: "Open sans";
  text-align: left;
  color: white;
}
.blockchainStyle {
  /* background-color: red; */
  min-height: 350px !important;
}
@media screen and (max-width: 1024px) {
  .blockchainStyle {
    min-height: 220px !important;
  }
}
@media screen and (max-width: 768px) {
  .Blockchain .mobileApp {
    align-items: center;
    margin-top: 50px;
  }
  .BlockchainCards h1 {
    font-size: 32px;
  }
  .BlockchainCards p {
    font-size: 16px;
  }
  .Blockchain .BlockchainTemplates .template_div {
    align-items: center;
    margin-top: 50px;
    text-align: center;
  }
  .Blockchain .BlockchainTemplates .leftImage {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .Blockchain .about_container h1 {
    text-align: center;
  }
  .Blockchain .about_container p {
    text-align: center;
  }
  .Blockchain .about_container h1 {
    font-size: 20px;
    line-height: 20px;
  }
  .Blockchain .about_container p {
    font-size: 13px;
  }
  .Blockchain .about_container button {
    padding: 10px 15px;
    font-size: 12px;
  }
  .BlockchainCards h1,
  .BlockchainCards .BlockchainCard h1,
  .Blockchain .BlockchainTemplates .template_div h1,
  .Blockchain .BlockchainTechnologies h1 {
    font-size: 20px;
  }
  .BlockchainCards p,
  .BlockchainCards .BlockchainCard p,
  .Blockchain .BlockchainTemplates .template_div p {
    font-size: 13px;
  }
  .Blockchain .BlockchainTemplates .template_div .list_style {
    font-size: 11px;
    width: 100%;
  }
  .blockchainStyle {
    min-height: 80px !important;
  }
}

.workflow_heading {
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: var(--text);

}
.about_container .margin  {
  margin-bottom: 150px;
}
.workflow_heading h1 {
  font-size: 35px;
  font-family: "Muli";
  color: var(--text);

}
.workflow_heading p {
  font-size: 16px;
  font-family: "Open sans";
  width: 55%;
  color: var(--text);

}
.WorkflowTemplate {
  width: 90%;
}
.WorkflowTemplate .image_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.WorkflowTemplate .image_div img {
  width: 80%;
}
.WorkflowTemplate .icon {
  background-color: var(--background);
  box-shadow: var(--boxShadow);
  width: 100px;
  height: 100px;
  padding: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WorkflowTemplate .right img {
  width: 70px;
  /* border-radius: 50%; */
}
.WorkflowTemplate .right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.WorkflowTemplate .right h1,
.WorkflowTemplate .left h1 {
  font-size: 32px;
  font-family: "Muli";
  color: var(--text);

}
.WorkflowTemplate .right p,
.WorkflowTemplate .left p {
  color: rgb(124, 124, 124);
  width: 80%;
  font-size: 16px;
  font-family: "Open sans";
  color: var(--text);

}
.WorkflowTemplate .right {
  padding-right: 30px;
}
.WorkflowTemplate .left {
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  .workflow_heading p {
    width: unset;
  }
  .about_container .margin {
    margin-bottom: unset;
  }
}
@media screen and (max-width: 425px) {
  .WorkflowTemplate .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 50px 0;
  }
  .WorkflowTemplate .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .WorkflowTemplate .left {
    text-align: center;
  }
  .WorkflowTemplate .image {
    display: none;
  }
  .workflow_heading h1 {
    font-size: 24px;
  }
  .workflow_heading p {
    font-size: 14px;
  }
  .WorkflowTemplate .right h1,
  .WorkflowTemplate .left h1 {
    font-size: 24px;
  }
  .WorkflowTemplate .right p,
  .WorkflowTemplate .left p {
    font-size: 14px;
  }
  .WorkflowTemplate .left {
    padding: unset;
  }
  .WorkflowTemplate .right {
    padding: unset;
  }
}

.client-testimonial{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* background: var(--primary); */
}
.client-testimonial .banner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
    padding: 60px 0;
}

.client-testimonial .banner p{
    width: 70%;
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin-bottom: 10px;
}
.client-testimonial .width90{
    width: 90%;
    padding: 60px 0;
}
.service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
}

.service-container .count {
    width: 90%;
    padding: 48px 0 64px;

}


.service-container .count .count-content h3 {
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 72px; */
    letter-spacing: -4px;
    text-align: center;
    color: var(--primary);
}

.service-container .count .count-content .days {
    font-size: 36px;
    letter-spacing: -1px;
    font-weight: 700;
    color: var(--primary);

}

.service-container .count .count-content p {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -.6px;
    text-align: center;
    color: #000;

}

.service-container .main-carousel {
    width: 80%;
    height: 460px;
    padding: 60px 0;
}

.service-container .solutions {
    width: 70%;
    padding: 75px 0;
}

.service-container .solutions .solutions-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-container .solutions .solutions-content h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    color: #000;
    margin-bottom: 24px;
    text-align: left;
}

.service-container .solutions .solutions-content h5 {
    color: #2e384d;
    opacity: .5;
    font-weight: 400;
    letter-spacing: -1.2px;
    font-size: 22px;
    line-height: 32px;
    width: 65%;
    margin-bottom: 3rem;
    text-align: center;
}

.service-container .solutions .solutions-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -.4px;
    text-align: left;
    color: #000;
}

.service-container .solutions .solutions-content .technologies {
    width: 90%;
    margin: 75px auto auto auto;
}

.service-container .experts {
    background-color: var(--primary);
    width: 100%;
    padding: 92px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .experts .experts-content {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .experts .experts-content h3 {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1px;
    text-align: center;
    color: #fff;
    margin-bottom: 24px;
}

.service-container .experts .experts-content p {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    color: #fff;
    margin-bottom: 48px;
}

.service-container .experts .experts-content button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .experts .experts-content button:hover {
    color: #fff;
    background: #000;
}

.service-container .dedicated-team {
    padding: 90px 0;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .dedicated-team .dedicated-team-content h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -2px;
    color: #000;
    margin-bottom: 24px;
}

.service-container .dedicated-team .dedicated-team-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -.4px;
    text-align: left;
    color: #000;
    margin-bottom: 40px;
}

.service-container .dedicated-team .context {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.service-container .dedicated-team .context .augmentation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .dedicated-team .context .augmentation img {
    width: 120px;
    height: 120px;
}

.service-container .dedicated-team .context .augmentation h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -.6;
    text-align: center;
}

.service-container .dedicated-team .context .leads {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .dedicated-team .context .leads img {
    width: 100%;
    height: auto;
}

.service-container .offer {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 96px 0;
}

.service-container .offer .offer-image {
    margin-bottom: 24px;
}

.service-container .offer .offer-image h2 {
    text-shadow: 2px 2px 0 #ffff, 2px -2px 0 #ffff, -2px 2px 0 #ffff, -2px -2px 0 #ffff, 2px 0px 0 #ffff, 0px 2px 0 #ffff, -2px 0px 0 #ffff, 0px -2px 0 #ffff;
    color: var(--primary);
    letter-spacing: 2.3px;
    font-size: 65px;
    line-height: 1.2;
    margin-bottom: 24px;
}

.service-container .offer .offer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.service-container .offer .offer-content p {
    max-width: 551px;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    color: #fff;
    text-align: left;
}

.service-container .offer .offer-content button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .offer .offer-content button:hover {
    color: #fff;
    background: #000;
}

.service-container .best-fit {
    padding: 90px 0;
    width: 75%;

}

.service-container .best-fit .best-fit-content h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    text-align: left;
    color: #000;
    margin-bottom: 24px;
}

.service-container .best-fit .best-fit-content p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #2e384d;
    opacity: .6;
    margin-bottom: 40px;
}

.service-container .best-fit .situation {
    padding: 24px 32px;
    cursor: pointer;
}

.service-container .best-fit .situation h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -.6px;
    text-align: left;
    color: var(--primary);
    text-transform: uppercase;
    margin-bottom: 16px;
}

.service-container .best-fit .situation .experties {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-container .best-fit .situation .experties p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
}

.service-container .best-fit .situation .experties svg {
    font-size: 20px;
    color: var(--primary);
}

.service-container .best-fit .solution {
    padding: 24px 32px 0 32px;

}

.service-container .best-fit .solution p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -.4px;
    text-align: left;
    color: #000;
}

.service-container .best-fit .solution h6 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.6px;
    text-align: left;
    color: #000;
    margin-top: 48px;
    margin-bottom: 14px;
}

.white-box {
    background-color: #fff;
    box-shadow: 0 20px 32px -4px rgb(16 24 40 / 6%), 0 8px 16px -4px rgb(16 24 40 / 2%);
    border-radius: 12px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .challenge {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 96px 0;
}

.service-container .challenge .sound-challenge {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .challenge .sound-challenge img {
    width: 100%;
}


.service-container .challenge .arrow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 40px;
}

.service-container .challenge .talk-btn {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    height: 170px;
    margin-left: 40px;
}

.service-container .challenge .talk-btn button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .challenge .talk-btn button:hover {
    color: #fff;
    background: #000;
}

.service-container .type-of-work {
    padding: 96px 0;
    width: 85%;
}

.service-container .type-of-work h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    text-align: left;
    color: #000;
    margin-bottom: 48px;
}

.service-container .type-of-work .type-of-work-content .flex {
    display: flex;
    justify-content: space-between;
}

.service-container .type-of-work .type-of-work-content .image-container {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #656c7b;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-container .type-of-work .type-of-work-content .items-container {
    width: 20%;
}

.service-container .type-of-work .type-of-work-content .items-container .items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 32px;
}

.service-container .type-of-work .type-of-work-content .items-container .items p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
}

.service-container .type-of-work .cooperation {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.service-container .type-of-work .cooperation img {
    width: 350px;
}

.service-container .tech-audit {
    background: url(/static/media/working-on-macbook.7da4d885.webp);
    background-repeat: no-repeat;
    background-position: right;
    height: 700px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
}

.service-container .tech-audit .technical-audit {
    background-color: #532456;
    min-height: 500px;
    padding: 0 32px;
    width: 635px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -400px;

}

.service-container .tech-audit .technical-audit h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -3px;
    text-align: left;
    color: #fff;
    margin-bottom: 24px;
}

.service-container .tech-audit .technical-audit p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #fff;
    opacity: .7;
    margin-bottom: 26px;
}

.service-container .tech-audit .technical-audit h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    color: #d91965;
    display: block;
    margin-bottom: 50px;
}

.service-container .tech-audit .technical-audit img {
    margin-left: 15px;
}

.service-container .tech-audit .technical-audit button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .tech-audit .technical-audit button:hover {
    color: #fff;
    background: #000;
}

.service-container .extend-work {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .extend-work .extend-work-content {
    width: 85%;
}

.service-container .extend-work .extend-work-content h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -2px;
    color: #000;
    margin-bottom: 24px;
}

.service-container .extend-work .extend-work-content p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #656c7b;
}

.service-container .extend-work .extend-work-content .qualities {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #d4d5d7;
    padding: 32px 0;
}

.service-container .extend-work .extend-work-content .qualities h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.6px;
    text-align: left;
    /* color: #d91965; */
    color: var(--primary);
    width: 60%;
}

.service-container .extend-work .extend-work-content .qualities p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -.3px;
    text-align: left;
    color: #000;
    width: 40%;
}

.service-container .awards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .awards .awards-content {
    width: 80%;
}

.service-container .awards .awards-content h2 {
    font-size: 64px;
    line-height: 72px;
    margin-top: 96px;
    font-weight: 700;
    letter-spacing: -3px;
    text-align: left;
    color: #000;
}

.service-container .awards .awards-content .awards-img {
    padding: 81px 0 137px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.service-container .awards .awards-content .awards-img img {
    width: 150px;
}

.service-container .with-decotechs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary);
    padding: 96px 0;
}

.service-container .with-decotechs .flex {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-container .with-decotechs .flex .team {
    width: 70%;
}

.service-container .with-decotechs .flex .team h2 {
    text-shadow: 2px 2px 0 #ffff, 2px -2px 0 #ffff, -2px 2px 0 #ffff, -2px -2px 0 #ffff, 2px 0px 0 #ffff, 0px 2px 0 #ffff, -2px 0px 0 #ffff, 0px -2px 0 #ffff;
    color: var(--primary);
    letter-spacing: 2.3px;
    font-size: 65px;
    line-height: 1.2;
    margin-bottom: 24px;
}

.service-container .with-decotechs .flex .team p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #fff;
    max-width: 551px;
}

.service-container .with-decotechs .flex .btn-container {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .with-decotechs .flex .btn-container p {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -.4px;
    text-align: left;
    color: #fff;
    padding: 10px 0;
}

.service-container .with-decotechs .flex .btn-container .hire {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    background: #000;
}

.service-container .with-decotechs .flex .btn-container .hire:hover {
    color: #000;
    background: #fff;
}

.service-container .with-decotechs .flex .btn-container .tech {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.service-container .with-decotechs .flex .btn-container .tech:hover {
    color: #fff;
    background: #000;
}

.service-container .schedule {
    width: 80%;
    padding: 96px 0;
}

.service-container .schedule .schedule-content h2 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -3px;
    color: #000;
    margin-bottom: 1.7rem;
    max-width: 550px;
}


.service-container .schedule .schedule-content p {
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: -.4px;
    margin-bottom: 3.61rem;
    color: #656c7b;
}

.service-container .schedule .expert .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-container .schedule .expert .flex .person {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    width: 50%;

}

.service-container .schedule .expert .flex .person img {
    width: 80px;
    border-radius: 100%;
}

.service-container .schedule .expert .flex .person .person-details h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #000;
}

.service-container .schedule .expert .flex .person .person-details p {
    font-size: 14px;
    line-height: 26px;
    color: #000;
}

.service-container .schedule .expert .flex .content {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.service-container .schedule .expert .flex .content p {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -.4px;
}

.service-container .schedule .booking {
    margin-top: 3.61rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-container .schedule .booking .flex {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.service-container .schedule .booking .flex h6 {
    font-size: 2rem;
    line-height: 1.55556;
    font-weight: 700;
    color: #000;
}

.service-container .schedule .booking .flex p {
    font-size: 22px;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-container .schedule .booking .flex svg {
    margin-left: 10px;
    font-size: 22px;

}

.service-container .schedule .booking .calendar {
    width: 70%;
    box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
    background: #fff;
    padding: 60px 120px;
}

.service-container .schedule .booking .calendar .calendar-box {
    box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
    border-radius: 8px;
    padding: 20px;
}

.service-container .schedule .booking .calendar .calendar-box .ant-picker-calendar-date-today {
    background: var(--primary) !important;
    border: 1px solid var(--primary);
}

.service-container .schedule .booking .calendar .calendar-box .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--primary) !important;
    border: 1px solid var(--primary);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
}

.service-container .schedule .booking .calendar .calendar-box .ant-radio-button-wrapper-checked {
    color: var(--primary);
    border-color: var(--primary);
    box-shadow: none;
}

.service-container .schedule .booking .calendar .calendar-box .ant-radio-button-wrapper:hover {
    color: var(--primary);
    border-color: var(--primary);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--primary);
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--primary);
}

.react-multi-carousel-dot-list {
    justify-content: right !important;
}

.situationresponsive {
    display: none;
}

@media (max-width:1440px) {
    .service-container .main-carousel {
        height: 560px;
    }
}

@media (max-width: 1250px) {
    .service-container .count .count-content h3 {
        font-size: 45px;
    }

    .service-container .count {
        width: 90%;
        padding: 40px 0 30px;
    }
}

@media (max-width: 1050px) {
    .service-container .solutions {
        width: 90%;
    }

    .service-container .solutions .solutions-content h5 {
        width: 95%;
    }

    .service-container .experts .experts-content {
        width: 90%;
    }

    .service-container .dedicated-team {
        width: 90%;
        padding-top: 60px;
        padding-bottom: 0;
    }

    .width65 {
        width: 90%;
    }

    .service-container .best-fit {
        width: 90%;
    }

    .service-container .type-of-work {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-container .type-of-work .type-of-work-content .flex {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-container .type-of-work .type-of-work-content .image-container {
        width: 90%;
    }

    .service-container .type-of-work .cooperation img {
        width: 250px;
    }

    .service-container .type-of-work .type-of-work-content .items-container {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .service-container .extend-work .extend-work-content {
        width: 90%;
    }

    .service-container .awards .awards-content {
        width: 90%;
    }

    .service-container .schedule .schedule-content h2 {
        max-width: 100%;
    }

    .service-container .challenge .talk-btn {
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .service-container .challenge .arrow {
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .service-container .challenge .sound-challenge img {
        width: 80%;
    }
}

@media (max-width:770px) {
    .service-container .main-carousel {
        height: 670px;
    }

    .service-container .with-decotechs .flex {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .service-container .with-decotechs .flex .team {
        width: 90%;

    }

    .service-container .with-decotechs .flex .btn-container {
        width: 90%;
        margin-top: 25px;
    }

    .service-container .schedule .expert .flex .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        grid-gap: 5px;
        gap: 5px;
    }

    .service-container .schedule .expert .flex .person .person-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-container .schedule .expert .flex {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .service-container .schedule .expert .flex .content {
        width: 90%;
    }

    .service-container .schedule .booking .flex {
        width: 100%;
    }

    .service-container .schedule .booking .calendar {
        width: 100%;
    }

    .service-container .schedule {
        width: 90%;
        padding: 60px 0;
    }

    .service-container .tech-audit .technical-audit {
        margin-left: 0;
    }

    .service-container .type-of-work {
        padding: 50px;
    }

    .service-container .extend-work .extend-work-content .qualities {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-container .extend-work .extend-work-content .qualities h3 {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .service-container .extend-work .extend-work-content .qualities p {
        text-align: center;
        width: 100%;
    }

    .service-container .with-decotechs .flex .team h2 {
        font-size: 38px;
    }

    .service-container .offer .offer-image h2 {
        font-size: 38px;
        text-align: center;
    }
    .service-container .offer .offer-content button{
        margin-top: 20px;
    }
    .service-container .offer .offer-content{
        justify-content: center;
    }
    .service-container .with-decotechs {
        padding: 60px 0;
    }

    .service-container .solutions .solutions-content .technologies {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin: 75px auto auto auto;
    }

    .situationdesktop {
        display: none;
    }

    .situationresponsive {
        display: block;

    }
}

@media (max-width:550px) {
    .service-container .main-carousel {
        height: 895px;
        width: 90%;
    }

    .service-container .schedule .booking .calendar {
        padding: 0;
    }

    .service-container .schedule {
        padding-bottom: 30px;
    }

    .service-container .challenge .sound-challenge img {
        width: 90%;
    }

}
.portfolio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.portfolio-container .head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 250px;
}

.portfolio-container .head h2 {
    font-size: 72px;
    font-weight: 700;
}

.portfolio-container .domains {
    margin-top: 30px;
    width: 95%;
    margin-bottom: 30px;
}

.portfolio-container .connect {
    background: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    flex-direction: column;
}

.portfolio-container .connect h3 {
    font-size: 50px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    width: 820px;
}

.portfolio-container .connect p {
    text-align: center;
    width: 820px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.2em;
}

.portfolio-container .connect button {
    width: 265px;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.portfolio-container .connect button:hover {
    color: #fff;
    background: #000;
}

@media (max-width: 770px) {
    .portfolio-container .connect h3 {
        width: 95%;
    }

    .portfolio-container .connect p {
        width: 95%;
    }

    .portfolio-container .head h2 {
        text-align: center;
    }

}
.client-testimonial{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* background: var(--primary); */
}
.client-testimonial .banner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
    padding: 60px 0;
}

.client-testimonial .banner p{
    width: 70%;
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin-bottom: 10px;
}
.client-testimonial .width90{
    width: 90%;
    padding: 60px 0;
}
.case-study{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.case-study .head{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
    padding: 60px 0;
}

.case-study .head h2{
    font-size: 46px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-decoration: underline;
}

.case-study .head p{
    width: 60%;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-top: 20px;

}

.case-study .card-div{
    width: 90%;
}


@media(max-width: 990px){
.case-study .head p{
    width: 90%;
}
} 


.block-chain .solution {
  padding: 70px 0px;
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.block-chain .solution h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .solution p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .features {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F7FF;
}

.block-chain .features h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .features p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .development-process {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.block-chain .development-process h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .development-process p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .leading-choice {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F7FF;
}

.block-chain .leading-choice h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .leading-choice p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .testimonial {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff
}

.block-chain .testimonial h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.block-chain .testimonial p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .faq {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff
}

.block-chain .faq h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.block-chain .contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.width85 {
  width: 85%;
}

.block-chain .waveImageStyle {
  margin-bottom: 0;
}

.block-chain .development-platforms {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
}

.block-chain .development-platforms h2 {
  color: #000;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.block-chain .development-platforms p {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

.block-chain .development-platforms .development-platforms-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.block-chain .development-platforms .development-platforms-card img {
  width: 72px;
}

.block-chain .development-platforms .development-platforms-card h3 {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}
.block-chain .talk-expert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(/static/media/Data-Analytics-1.32cfeb04.jpg);
    padding: 70px 0;
}

.block-chain .talk-expert h2{
    color: #FFF;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.block-chain .talk-expert p{
    text-align: center;
    color: #F4F4F4;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

.block-chain .talk-expert button{
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .3px;
    color: #FFF;
    background: #15A70000;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #DBDBDB;
    border-radius: 7px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.choose-us {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.choose-us .bg-left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/static/media/Path-2.1a49d008.png);
    background-repeat: no-repeat;
    background-position: right;
}

.choose-us .bg-left h2 {
    color: #000;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}

.choose-us .bg-left p {
    color: #333;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 50px;
}

.choose-us .why-us {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choose-us .why-us .border-right-bottom {}

.choose-us .why-us .border-right-bottom h3 {}

.choose-us .why-us .border-right-bottom p {}

.choose-us .why-us .border-left-bottom {}

.choose-us .why-us .border-left-bottom h3 {}

.choose-us .why-us .border-left-bottom p {}
/* Third Party CSS */

/* Theme CSS */

/* Layout CSS */

/* Component CSS */


/* Page CSS */



