@font-face {
  font-family: "Muli";
  /* src: url('../../assets/fonts/Muli-Bold.ttf'); */
  src: url("../../assets/fonts/Muli-Bold.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.parent_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .text_container img {
  margin-top: 30px;
} */
::-webkit-scrollbar {
  display: none;
}
.techandapp_container .whiteWave {
  position: relative;
  bottom: 90px;
}
.techandapp_container .text_container div div {
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.techandapp_container .TechAppCard h1 {
  font-family: "Mulish";
  font-size: 24px;
  letter-spacing: -1px;
  margin-top: 10px;
  font-weight: 900;
  color: var(--text);
}
.techandapp_container .text_container div div div {
  flex-direction: row;
}
.techandapp_container .text_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.TechAppCard p {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 28px;
  margin-top: 10px;
  color: var(--text);

  /* width: 95%; */
}
.techandapp_container .text_container .mobileApp .btn_div {
  display: flex;
  gap: 20px;
}
.techandapp_container .text_container .mobileApp .btn_div button {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.techandapp_container .text_container .mobileApp .btn_div button:hover {
  /* animation: tp-scaleout 1s infinite ease-in-out; */
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.15);
  /* margin: 2px 0; */
  transform: translateY(-0.25em);
}
.techandapp_container .DesignCard .icon_div {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.techandapp_container .DesignCard .icon {
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 20px;
}
.techandapp_container .DesignCard .icon:hover {
  /* position: relative;
  left: 5px; */
  padding-left: 4px;
  font-size: 25px;
}
.textStyle-More {
  /* background: rgb(255, 255, 255); */
  /* background: linear-gradient(
    180deg,
    rgba(255,255,0,0) 70%
     rgb(255,255,200) ,
  ); */
  background: linear-gradient(
    transparent 67%,
    #00b3eb 67%,
    #00b3eb 90%,
    var(--background) 90%,
    var(--background) 100%
  );
}
.textStyle-Clients {
  /* background: rgb(255, 255, 255); */
  /* background: linear-gradient(
    180deg,
    rgba(255,255,0,0) 70%
     rgb(255,255,200) ,
  ); */
  background: linear-gradient(
    transparent 67%,
    #00b3eb 67%,
    #00b3eb 90%,
    var(--background) 90%,
    var(--background) 100%
  );
}
.blockchain_textStyle {
  background: linear-gradient(
    transparent 67%,
    #00b3eb 67%,
    #00b3eb 90%,
    transparent 90%,
    transparent 100%
  );
}
.Parallax_image {
  height: 80vh;
}
.waveImageStyle {
  /* margin-bottom: -150px; */
}
.slick-dots li button {
  background-color: gray !important;
}
@media screen and (max-width: 768px) {
  .techandapp_container .text_container .mobileApp {
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-items: self-start; */
    /* margin-top: 100px; */
    text-align: center;
    /* margin: 100px 0; */
  }
  .form_container {
    margin-bottom: 10px !important;
  }
  .Parallax_image {
    height: 80vh;
  }
  .statistic_container {
    height: 420px !important;
  }
  .techandapp_container .analysisCards {
    padding: 50px 0 !important;
  }
  .techandapp_container .whiteWave {
    display: none;
  }
  .parent_container .techAppCard_container .techAppCard_child {
    margin-bottom: 30px;
  }
  .techandapp_container .plansCards_conatiner {
    align-items: center !important;
    margin: 50px 0;
  }
  .appDevelopment_container .img {
    display: none;
  }
  .techandapp_container .DesignCard img {
    width: 100% !important;
    height: unset !important;
  }
  .heading {
    margin: unset !important;
  }
}
@media screen and (max-width: 425px) {
  .techandapp_container .analysisCards {
    display: unset !important;
  }
  .techandapp_container .text_container h1 {
    font-size: 25px !important;
    text-align: left;
    width: 100% !important;
    color: var(--text);
  }
  .techandapp_container .text_container p {
    width: unset !important;
    font-size: 14px !important;
    text-align: left;
    color: var(--text);
  }
  .techandapp_container .text_container .btn_div {
    flex-direction: row;
    margin-top: 10px;
  }
  .techandapp_container .text_container div div {
    gap: unset;
  }
  .plansCards_conatiner h1 {
    font-size: 24px !important;
    color: var(--text);
  }
  .plansCards_conatiner p {
    font-size: 14px !important;
    color: var(--text);
  }
  .techandapp_container .progress h1 {
    font-size: 24px !important;
    color: var(--text);
  }
  .techandapp_container .progress p {
    font-size: 14px !important;
    font-weight: normal !important;
    color: var(--text);
  }
  .appDevelopment_container .FormText .heading {
    font-size: 24px !important;
  }
  .appDevelopment_container .FormText p {
    font-size: 14px !important;
    color: var(--text);
  }
  /* .techandapp_container .text_container div div div {
    flex-direction: column;
  } */
  .techandapp_container .statistic_container .main .allStats {
    width: 200px !important;
    height: 300px !important;
  }
  .techandapp_container .statistic_container .main .salesGraf {
    width: 200px !important;
    height: 200px !important;
  }
  .techandapp_container .statistic_container .main .group {
    width: 100px !important;
    height: 100px !important;
    right: 40px !important;
  }
  /* .FormText .contact {
    display: unset !important;
  } */
  .FormText .contact .pakInfo .location .icon svg{
    font-size: 20px;
  }
  .FormText .address {
    display: unset !important;
  }
  .techandapp_container .plansCards_conatiner {
    align-items: unset !important;
  }
  .Parallax_image {
    height: 38vh;
  }
  .statistic_container {
    height: 300px !important;
  }
}
.techandapp_container .text_container h1 {
  /* font-size: 72px; */
  font-size: 32px;
  width: 500px;
  font-weight: 800;
  line-height: 40px;
  font-family: "Muli";
  color: var(--text);
}
.techandapp_container .text_container p {
  /* font-size: 20px; */
  font-size: 16px;
  /* width: 570px; */
  /* font-family: "Roboto", sans-serif; */
  font-family: "Open Sans", sans-serif;
  color: #444444;
  color: var(--text);
}
.techandapp_container .techAppCard_container {
  width: 93%;
  padding: 40px;
  box-shadow: 0px 15px 44px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  /* margin-bottom: 50px; */
}
.parent_container .appDevelopment_container {
  width: 90%;
}
.plansCards_conatiner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  /* font-family: Open Sans; */
}
.plansCards_conatiner button {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.plansCards_conatiner button:hover {
  /* transform: translateY(-0.25em); */
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.15);
  transform: translateY(-0.25em);
}
.plansCards_conatiner h1 {
  font-size: 32px;
  font-weight: 800;
  font-family: "Muli";
  letter-spacing: -1px;
  color: var(--text);
}
.plansCards_conatiner p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: var(--text);
}
.techandapp_container .analysisCard_container {
  border: 1px solid #eeeded;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.techandapp_container .analysisCard_container:hover {
  box-shadow: 0px 15px 33px 0 rgb(0 0 0 / 14%);
  transform: translateY(-0.25em);
}
.techandapp_container .analysisCard_container h2 {
  font-family: "Muli";
  font-size: 18px;
  letter-spacing: -1px;
  color: var(--text);
}
.techandapp_container .analysisCard_container p {
  font-family: "Open sans" sans-serif;
  font-size: 14px;
  color: var(--text);
}
/* .techandapp_container .analysisCards div {
  display: flex;
  flex-direction: column;
  gap: 20px;
} */
.techandapp_container .analysisCards {
  padding: 100px 0;
  display: flex;
  gap: 30px;
}
.techandapp_container .analysisCards .analysisCards_child {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}
.techandapp_container .analysisCards .icon {
  /* background-color: #ffbd4b; */
  padding: 10px;
  border-radius: 50%;
  color: white;
}
.techandapp_container .progress {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.techandapp_container .progress h1 {
  font-size: 32px;
  font-weight: 800;
  font-family: "Muli";
  letter-spacing: -1px;
  color: var(--text);
}
.techandapp_container .progress p {
  /* color: #5a73c6; */
  font-size: 14px;
  font-weight: 800;
  font-family: "Open sans" sans-serif;
  color: var(--text);
}
/* .techandapp_container .progress div {
  margin-bottom: 5px;
} */
.techandapp_container .progress div div {
  display: flex;
  justify-content: space-between;
}
.statistic_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
}
.statistic_container .main {
  position: relative;
  width: 600px; /*here is the changes */
  height: 600px; /*here is the changes */
  display: flex;
  align-items: center;
  justify-content: center;
}
.statistic_container .main .group {
  position: absolute;
  width: 300px;
  height: 300px;
  right: 10px;
}
.statistic_container .main .salesGraf {
  position: absolute;
  left: 13px;
  /* top: 55px; */
  width: 400px;
  height: 400px;
}
.statistic_container .main .allStats {
  position: absolute;
  /* top: 0px; */
  width: 400px;
  height: 500px;
}
.appDevelopment_container .FormText h1 {
  font-size: 15px;
  color: var(--text);
}
.appDevelopment_container .FormText .heading {
  font-size: 32px;
  font-family: "Muli";
  letter-spacing: -1px;
}
.appDevelopment_container .FormText p {
  /* font-size: 16px; */
  font-family: "Open sans" sans-serif;
  color: var(--text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.appDevelopment_container .FormText p a{
  color: var(--text);
  cursor: pointer;
}
.appDevelopment_container .FormText svg{
  margin-right: 10px;
}
.appDevelopment_container .FormText h1 {
  font-family: "Muli";
  font-size: 20px;
  color: var(--text);
}
.appDevelopment_container .form_container h1 {
  font-family: "Muli";
  font-size: 32px;
  color: var(--text);
}
.appDevelopment_container .form_container textarea {
  padding: 20px;
}
.appDevelopment_container .form_container button {
  font-family: "Muli";
  font-size: 12px;
  background-color: var(--primary);
  color: white;
  padding: 22px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appDevelopment_container .form_container button:hover {
  background-color: black;
}
.ProgressChart {
  margin-bottom: 50px;
}
@media screen and (max-width: 500px) {
  .techandapp_container .text_container h1 {
    width: unset;
  }
}
@media screen and (max-width: 1024px) {
  .statistic_container .main .group {
    width: 200px;
    height: 200px;
  }
  .waveImageStyle {
    /* margin-bottom: -120px; */
  }
  .statistic_container .main .salesGraf {
    width: 300px;
    height: 300px;
  }
  .statistic_container .main .allStats {
    width: 300px;
    height: 400px;
  }
}
.techandapp_container .analysisCard_container {
  min-height: 210px;
  flex-direction: column;
}
.techandapp_container .DesignCard {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.techandapp_container .DesignCard a {
  font-size: 11px;
  color: black;
  font-family: "Muli";
}
.techandapp_container .DesignCard h1 {
  font-weight: 800;
  font-family: "Muli";
  font-size: 22px;
  letter-spacing: -1px;
}
.techandapp_container .DesignCard h1:hover,
.techandapp_container .DesignCard a:hover {
  color: #f9b94a !important;
}
.techandapp_container .DesignCard img {
  width: 350px;
  height: 200px;
}
.FormText {
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
  gap: 20px;
}
.FormText h1 {
  font-weight: 800;
}
.FormText .address {
  display: flex;
  justify-content: space-between;
}
.FormText .contact {
  display: flex;
  justify-content: space-between;
}
.FormText .contact .pakInfo{
  margin-top: 1rem;
}
.FormText .contact .icon {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-right: 50px; */
}
.FormText .contact .icon div {
  display: flex;
  gap: 10px;
}
.FormText .contact .icon div svg:hover {
  color: var(--primary);
  cursor: pointer;
}
.form_container input,
Textarea {
  border-radius: 20px;
}
.form_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 100px;
}
.form_container .textArea {
  border-radius: 20px;
}
.form_container div {
  width: 100%;
  display: flex;
  gap: 10px;
}
.techandapp_container .heading {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1px;
}
span {
  /* z-index: 2; */
  /* mix-blend-mode: multiply; */
}
span .textStyle {
  /* z-index: -1; */
  top: 90px;
  position: relative;
  background-color: aqua;
  width: 240px;
  height: 30px;
}
@media screen and (max-width: 425px) {
  .waveImageStyle {
    /* margin-bottom: -50px; */
  }
  .statistic_container .main .group {
    left: 210px;
  }
  .statistic_container .main .salesGraf {
    right: 113px;
    left: unset;
  }
  .techandapp_container .progress {
    padding-bottom: 20px;
  }
  .ProgressChart {
    margin-bottom: 20px !important;
  }
  .main,
  .statistic_container {
    display: none !important;
  }
}
@media screen and (max-width: 375px) {
  /* .statistic_container .main .group {
    left: 155px;
  } */
  /* .statistic_container .main .salesGraf {
    right: 70px;
  } */
  .statistic_container .main .group {
    left: 155px;
  }
  .statistic_container .main .salesGraf {
    right: 70px;
  }
}
@media screen and (max-width: 320px) {
  .techandapp_container .statistic_container .main .group {
    right: 10px !important;
  }
  .Parallax_image {
    height: 54vh;
  }
  .statistic_container .main .group {
    left: 104px;
  }
  .statistic_container .main .salesGraf {
    right: 10px;
  }
  .statistic_container .main .allStats {
    width: 240px;
    height: 340px;
  }
}
