.main-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 20px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
}

.main-card .card-image {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-card .card-image img {
    max-width: 100%;
}

.main-card h3 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;

}

.main-card p {
    font-size: 16px;
    text-align: center;
    height: 250px;
}

.main-card button {
    color: #fff;
    background: var(--primary);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.main-card button:hover {
    background: #000;
    color: #fff;
}
@media (max-width: 1050px) {
    .main-card p {
        height: 290px;
    }
}

@media (max-width: 770px) {
    .main-card p {
        height: 230px;
    }
}