.portfolio_text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px;
  font-size: 32px;
  font-family: "Muli";
  word-spacing: -1px;
  color: var(--text);
}
.portfolio_text h1 {
  color: var(--text);
}
.PortfolioData {
  width: 90%;
}
.PortfolioData .filter {
  display: flex;
  justify-content: space-between;
}
.PortfolioData .filter p {
  font-family: "Muli";
  font-weight: bold;
  cursor: pointer;
  color: var(--text);
}
.PortfolioData .filter div {
  display: flex;
  gap: 20px;
}
.PortfolioData .PortfolioCard {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 280px;
  /* height: 200px; */
  background-size: contain;
  background-repeat: no-repeat;
  /* display: flex;
  justify-content: center;
  align-items: flex-end; */
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
}
.PortfolioData .PortfolioCard:hover img {
  transition: 0.3s ease-in;
  transform: scale(1.1);
}
.PortfolioData .PortfolioCard img {
  width: 100%;
  height: 100%;
}
.PortfolioData .PortfolioCard .text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--text);
}
.PortfolioData .PortfolioCard .text h1 {
  color: var(--text);
  text-shadow: var(--textShadow);
  visibility: hidden;
}
.PortfolioData .PortfolioCard .text p {
  visibility: hidden;
  text-shadow: var(--textShadow);
  color: var(--text);
}
.PortfolioData .PortfolioCard:hover h1,
.PortfolioData .PortfolioCard:hover p {
  visibility: visible;
  color: var(--text);
}
@media screen and (max-width: 425px) {
  .portfolio_text {
    font-size: 16px;
    padding: 50px;
  }
  .PortfolioData .filter {
    font-size: 10px;
  }
}
